import { Link } from 'react-scroll';

import Heading from '../../shared/Heading';

const RestaurantCategoryCard = ({ categories }) => {
  const active = 'font-bold text-secondary';

  return (
    <>
      {categories?.length > 0 ? (
        <div className="sticky top-2 mb-10 hidden md:block">
          <Heading text="Categories" />
          <div className="bg-white rounded-2xl px-2 py-4 mt-2 space-y-2">
            {categories?.map((category) => {
              return (
                <div key={category.ID} className="text-dark ml-2 mb-1 cursor-pointer hover:text-secondary hover:opacity-90">
                  <Link activeClass={active} to={category.Name} spy={true} smooth={true} duration={1000}>
                    {category.Name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RestaurantCategoryCard;
