import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import CheckoutDetails from '../components/Checkout/CheckoutDetails';
import CheckoutSummary from '../components/Checkout/CheckoutSummary';
import DynamicIcon from '../components/shared/DynamicIcon';

const Checkout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <div className="h-full px-4" style={styles.gradient}>
        <div className="flex flex-col mx-auto max-w-7xl">
          <div className="mt-4">
            <div className="flex items-center cursor-pointer hover:opacity-90 w-max" onClick={() => navigate(-1)}>
              <div className="flex items-center self-end justify-center w-full h-12 transition-all duration-200 ease-in rounded-full cursor-pointer sm:mx-0 sm:h-10 sm:w-10 text-primary bg-gray-50">
                <DynamicIcon icon="ChevronLeftIcon" width="w-8" height="h-8" aria-hidden="true" />
              </div>
              <span className="ml-2 font-medium text-white text-md">Go back</span>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 mt-5">
            <div className="col-span-8">
              <CheckoutDetails />
            </div>
            <div className="col-span-4">
              <CheckoutSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  gradient: {
    background: 'linear-gradient(to bottom, #1d1d1d 0px, #1d1d1d 400px, #F9FAFC 20%, #F9FAFC 100%)'
  }
};

export default Checkout;
