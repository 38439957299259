import { useEffect, useState } from 'react';

import axiosPrivate from '../../../api/axiosPrivate';
import useAuth from '../../../hooks/useAuth';
import AppButton from '../../shared/AppButton';
import DynamicIcon from '../../shared/DynamicIcon';
import AppModal from '../../shared/FormElements/AppModal';

const ChangePasswordModal = ({ open, toggleModal }) => {
  const [password, setPassword] = useState('');

  const { user, token } = useAuth();

  const handlePasswordChange = async () => {
    console.log('change password');
    // try {
    //   const data = {
    //     Password: password
    //   };
    //   console.log(data);
    //   const response = await axiosPrivate('UpdateUser', token, data);
    //   console.log('response', response);

    //   // TODO: close modal
    //   // TODO: refetch user in background
    //   close();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <>
      <AppModal open={open} close={toggleModal}>
        <div className="flex flex-col bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-3xl">
          <div className="flex flex-col space-y-4 bg-white">
            <div className="text-dark">
              <div className="flex justify-between">
                <h1 className="text-3xl font-medium"> Change Password </h1>
                <div
                  onClick={toggleModal}
                  className="flex h-12 w-full self-end items-center justify-center rounded-full text-primary bg-gray-50 sm:mx-0 sm:h-10 sm:w-10 hover:bg-gray-100 cursor-pointer transition-all ease-in duration-200">
                  <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
                </div>
              </div>
              <div className="mt-6">
                <div className="my-2">
                  <form className="w-96 min-w-max" action="#" method="POST" onSubmit={handlePasswordChange}>
                    <div className="flex flex-col space-y-4">
                      <div className="text-left">
                        <label htmlFor="password" className="">
                          New Password*
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="text"
                          autoComplete="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                          className="mt-0.5 rounded-xl bg-transparent w-full px-3 py-3 border-2 border-dark placeholder-gray-300 text-dark text-left focus:outline-none focus:ring-primary focus:border-primary focus:z-10 text-md"
                          placeholder="strongpassword123"
                        />
                      </div>
                    </div>

                    <div className="mt-8">
                      <AppButton text="Save" disabled={!password.length} rounded width="w-full" onClick={handlePasswordChange} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default ChangePasswordModal;
