import React from 'react';

const TextareaElement = ({ placeholder, labelText }) => {
  return (
    <>
      <label className="">{labelText}</label>
      <textarea
        className="mt-0.5 rounded-xl bg-transparent w-full px-3 py-3 border-2 border-dark placeholder-gray-300 text-dark text-left focus:outline-none focus:ring-primary focus:border-primary focus:z-10 text-md"
        placeholder={placeholder}
      />
    </>
  );
};

export default TextareaElement;
