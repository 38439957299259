import { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import DynamicIcon from '../shared/DynamicIcon';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

export default function AuthModal({ open, close, modalType }) {
  const [type, setType] = useState(modalType || 'login');
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="transition duration-500 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-500 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0">
          <div className="fixed inset-0 transition-opacity bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full mx-1 text-center">
            <Transition.Child
              as={Fragment}
              className="transition duration-700"
              enter="ease-in-out"
              enterFrom="translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="ease-out"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="translate-y-full opacity-0">
              <Dialog.Panel className="relative max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl">
                <div className="flex flex-col p-6 bg-white rounded-3xl">
                  <div className={`${type !== 'login' ? 'justify-between' : 'justify-end'} flex w-full`}>
                    {type !== 'login' && (
                      <div className="flex items-center cursor-pointer hover:opacity-90" onClick={() => setType('login')}>
                        <div className="flex items-center self-end justify-center w-10 h-10 mx-0 transition-all duration-200 ease-in rounded-full cursor-pointer text-primary bg-gray-50 hover:bg-gray-100">
                          <DynamicIcon icon="ChevronLeftIcon" width="w-8" height="h-8" aria-hidden="true" />
                        </div>
                        <span className="ml-2 text-dark text-md">Log in</span>
                      </div>
                    )}
                    <div
                      onClick={close}
                      className="flex items-center self-end justify-center w-10 h-10 mx-0 transition-all duration-200 ease-in rounded-full cursor-pointer text-primary bg-gray-50 hover:bg-gray-100">
                      <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    {type === 'login' ? (
                      <LoginForm close={close} switchType={() => setType('register')} />
                    ) : (
                      <RegisterForm close={close} switchType={() => setType('login')} />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
