import { useContext, useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import Footer from '../components/shared/Footer';
import Navbar from '../components/shared/Navbar';

const Main = () => {
  return (
    <div className="min-h-screen h-96">
      <div id="navbar">
        <Navbar />
      </div>
      <div id="detail" className="w-full min-h-screen bg-gray-50 h-fit">
        <div className="flex items-center w-full mx-auto">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
