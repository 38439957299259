import React from 'react';

const RestaurantSearchCard = ({ name, address, city, zip, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col items-center justify-center w-full px-5 py-8 text-center transition-all duration-100 ease-in-out bg-white cursor-pointer sm:w-72 rounded-xl hover:opacity-90">
      <h1 className="text-xl font-bold text-dark">{name}</h1>
      <p className="text-lg text-dark">{address},</p>
      <p className="text-lg text-dark">
        {zip} {city}
      </p>
    </div>
  );
};

export default RestaurantSearchCard;
