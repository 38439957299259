import { useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CartMobile from '../../components/Restaurant/CartMobile';
import RestaurantCheckoutCard from '../../components/Restaurant/RestaurantCheckoutCard';
import RestaurantHeader from '../../components/Restaurant/RestaurantHeader';
import RestaurantNavigation from '../../components/Restaurant/RestaurantNavigation';
import DynamicIcon from '../../components/shared/DynamicIcon';
import useRestaurant from '../../hooks/useRestaurant';
import { useGetRestaurant } from '../../hooks/useRestaurantQuery';

const Restaurant = () => {
  const [cartModal, setCartModal] = useState(false);

  const { restaurant, setRestaurant, restaurantId } = useRestaurant();
  const navigate = useNavigate();

  const onSuccess = (data) => {
    console.log(data);
    setRestaurant(data.data?.Data);
  };

  const onError = (error) => {
    console.log(error);
    toast.error('Something went wrong, please try again later!');
    navigate('/order');
    // TODO: throw error toast and get back to home page
  };

  const toggleCartModal = () => {
    setCartModal(!cartModal);
  };

  const { data } = useGetRestaurant(onSuccess, onError, restaurantId);

  return (
    <div className="w-full h-full">
      {/* <div className=""> */}
      <RestaurantHeader restaurant={restaurant} />
      {/* </div> */}
      <RestaurantNavigation />
      {/* TODO: add text - no menu possible right now, come back later if menus are empty!! */}
      <div className="grid w-full grid-cols-12 px-4 mx-auto max-w-7xl">
        <div className="col-span-12 my-5 lg:col-span-9">
          <Outlet context={{ restaurant }} />
        </div>
        <div className="hidden h-full my-5 lg:block lg:col-span-3">
          <RestaurantCheckoutCard />
        </div>
      </div>
      <div
        className="fixed flex items-center justify-center bg-white border-2 rounded-full shadow-md border-primary w-14 h-14 bottom-5 left-5 lg:hidden"
        onClick={toggleCartModal}>
        <DynamicIcon icon="ShoppingCartIcon" height="h-8" width="w-8" />
      </div>
      <CartMobile open={cartModal} close={toggleCartModal} />
    </div>
  );
};

export default Restaurant;
