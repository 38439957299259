import RecentOrderItem from './RecentOrderItem';

const RecentOrderList = () => {
  return (
    <div className="flex flex-col space-y-4">
      <RecentOrderItem />
      <RecentOrderItem />
      <RecentOrderItem />
    </div>
  );
};

export default RecentOrderList;
