import React, { useContext } from 'react';

import { NavLink, useMatch } from 'react-router-dom';

import RestaurantContext from '../../contexts/RestaurantContext';

const RestaurantNavigation = () => {
  const { restaurant } = useContext(RestaurantContext);

  const menuPath = useMatch({
    path: '/restaurant/:slug',
    exactly: false
  });

  const infoPath = useMatch({
    path: '/restaurant/:slug/info',
    exactly: false
  });

  const pages = [
    { name: 'Menu', path: `/restaurant/${restaurant?.Name}`, end: true, active: menuPath },
    { name: 'Info', path: `/restaurant/${restaurant?.Name}/info`, active: infoPath }
  ];

  const activeLink = 'text-primary font-medium border-b-2 border-primary';

  return (
    <div className="bg-white h-14 min-h-fit -mt-8 z-50 relative rounded-t-xl ">
      <div className="w-full max-w-7xl flex flex-row mx-auto h-full items-end space-x-5 px-4">
        {pages.map((page) => (
          <NavLink key={page.name} end={page.end} to={page.path} className={page.active ? activeLink : 'text-dark border-b-2 border-transparent'}>
            <p className="text-2xl hover:text-primary"> {page.name} </p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default RestaurantNavigation;
