import { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import axiosPrivate from '../../../api/axiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { useUpdateUser } from '../../../hooks/useUserQuery';
import AppButton from '../../shared/AppButton';
import DynamicIcon from '../../shared/DynamicIcon';
import AppModal from '../../shared/FormElements/AppModal';
import InputElement from '../../shared/FormElements/InputElement';

const EditProfileModal = ({ open, toggleModal }) => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const { user, token } = useAuth();

  useEffect(() => {
    if (user) {
      setFullName(user?.Name);
      setPhoneNumber(user?.Phone);
      setEmail(user?.Email);
    }
  }, [user]);

  const clearFields = () => {
    setFullName('');
    setPhoneNumber('');
    setEmail('');
  };

  const { mutate: updateUser } = useUpdateUser();

  const handleEdit = (e) => {
    e.preventDefault();

    const userData = {
      data: {
        // Email: email,
        Phone: phoneNumber,
        Name: fullName
      },
      token
    };
    updateUser(userData);
    // onSuccess - toggleModal, success toast
    // onError - error message
    toggleModal();
  };

  // const editUser = async (userData) => {
  //   const response = await axiosPrivate('UpdateUser', token, userData);
  //   console.log('response', response);
  // };

  return (
    <>
      <AppModal open={open} close={toggleModal}>
        <div className="flex flex-col bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-3xl">
          <div className="flex flex-col space-y-4 bg-white">
            <div className="text-dark">
              <div className="flex justify-between">
                <h1 className="text-3xl font-medium"> Edit Profile </h1>
                <div
                  onClick={toggleModal}
                  className="flex h-12 w-full self-end items-center justify-center rounded-full text-primary bg-gray-50 sm:mx-0 sm:h-10 sm:w-10 hover:bg-gray-100 cursor-pointer transition-all ease-in duration-200">
                  <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
                </div>
              </div>
              <div className="mt-6">
                <div className="my-2">
                  <form className="w-96 min-w-max" action="#" method="POST" onSubmit={(e) => handleEdit(e)}>
                    <div className="flex flex-col space-y-4">
                      <InputElement
                        labelText="Full Name*"
                        name="fullName"
                        placeholder="John Doe"
                        required
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      <InputElement
                        labelText="Phone Number*"
                        name="phoneNumber"
                        placeholder="Phone number*"
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {/* <InputElement
                        labelText="Email Address*"
                        name="email"
                        type="email"
                        placeholder="Email address*"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      /> */}
                    </div>
                    <div className="mt-8">
                      <AppButton
                        text="Save"
                        type="submit"
                        disabled={!fullName.length || !phoneNumber.length || !email.length}
                        rounded
                        width="w-full"
                        // onClick={handleEdit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default EditProfileModal;
