import DynamicIcon from '../../shared/DynamicIcon';

const RecentOrderItem = () => {
  return (
    <div className="flex hover:bg-gray-100 rounded-xl px-2 py-4 w-full justify-between items-center hover:cursor-pointer transition-all duration-300 ease-in-out">
      <div>2022-10-08 21:08</div>
      <div className="flex items-center space-x-5">
        <div>160.00 DKK</div>
        <div>
          <DynamicIcon icon="ChevronRightIcon" width="w-5" height="h-5" />
        </div>
      </div>
    </div>
  );
};

export default RecentOrderItem;
