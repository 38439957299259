import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div id="error-page" className="mt-44 w-full flex flex-col items-center justify-center text-center space-y-10">
      <h1 className="text-7xl font-semibold">Oops!</h1>
      <p className="text-xl">Sorry, an unexpected error has occurred.</p>
      <p>
        <i className="hover:underline cursor-pointer" onClick={() => navigate('/order', { replace: true })}>
          Go back home
        </i>
      </p>
    </div>
  );
};

export default ErrorPage;
