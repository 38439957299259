import { useState } from 'react';

import dayjs from 'dayjs';

import Heading from '../../components/shared/Heading';
import ChangePasswordModal from '../../components/User/PersonalInfo/ChangePasswordModal';
import EditProfileModal from '../../components/User/PersonalInfo/EditProfileModal';
import UserActionButton from '../../components/User/PersonalInfo/UserActionButton';
import UserInfoCard from '../../components/User/PersonalInfo/UserInfoCard';
import useAuth from '../../hooks/useAuth';

const UserProfileInfo = () => {
  const [editModal, setEditModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const { user } = useAuth();

  // create a function that creates user initials from a string like this "John Doe"
  const createInitials = (name) => {
    if (!name) return;
    const nameArray = name.split(' ');
    const initials = nameArray[0].charAt(0) + nameArray[1].charAt(0);
    return initials;
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const togglePasswordModal = () => {
    setPasswordModal(!passwordModal);
  };

  return (
    <div className="">
      <Heading text="Profile info" big />
      <div className="flex flex-col items-center">
        <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
          <p className="text-3xl">{createInitials(user?.Name)}</p>
        </div>
        <div className="mt-3 flex flex-col items-center justify-center">
          <h1 className="text-2xl font-bold text-dark">{user?.Name}</h1>
          <i className="text-sm text-dark">Joined: {dayjs(user?.CreateDate).format('YY-MM-DD')}</i>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 mt-10 items-center justify-center">
        <UserInfoCard title="Contact info">
          <p className="">
            Email: <span className="text-gray-700">{user?.Email}</span>
          </p>
          <p className="">
            Phone: <span className="text-gray-700">{user?.Phone}</span>
          </p>
        </UserInfoCard>
        <div className="flex flex-col space-y-2">
          <UserActionButton text="Edit Profile" icon="PencilIcon" onClick={toggleEditModal} />
          <UserActionButton text="Change Password" icon="LockClosedIcon" onClick={togglePasswordModal} />
          <UserActionButton text="Delete Profile" icon="MinusCircleIcon" backgroundColor="bg-red-500" textColor="text-white" borderColor="border-red-500" />
        </div>
      </div>
      <EditProfileModal open={editModal} toggleModal={() => toggleEditModal()} />
      <ChangePasswordModal open={passwordModal} toggleModal={() => togglePasswordModal()} />
    </div>
  );
};

export default UserProfileInfo;
