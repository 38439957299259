import DynamicIcon from '../../shared/DynamicIcon';

const UserActionButton = ({ icon, backgroundColor, borderColor, textColor, onClick, text }) => {
  return (
    <div
      className={`${backgroundColor ? backgroundColor : 'bg-white'} ${textColor ? textColor : 'text-dark'} ${
        borderColor ? borderColor : 'border-dark'
      } flex space-x-2 items-center h-12 min-h-fit justify-center border-2 p-3 rounded-lg hover:opacity-80 transition-all duration-300 cursor-pointer`}
      onClick={onClick}>
      <DynamicIcon icon={icon} width="w-6" height="h-6" />
      <p className="text-lg">{text}</p>
    </div>
  );
};

export default UserActionButton;
