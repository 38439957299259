import { createContext, useState } from 'react';

const OrderContext = createContext({});

export const OrderProvider = ({ children }) => {
  const [mode, setMode] = useState('');
  const [selectedTimeOption, setSelectedTimeOption] = useState('As soon as possible');
  const [selectedAddress, setSelectedAddress] = useState(null);

  return (
    <OrderContext.Provider
      value={{
        mode,
        setMode,
        selectedTimeOption,
        setSelectedTimeOption,
        selectedAddress,
        setSelectedAddress
      }}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
