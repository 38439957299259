import { Fragment, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';

import axiosPublic from '../../../api/axiosPublic';
import noImage from '../../../assets/images/no_image.png';
import useCart from '../../../hooks/useCart';
import useRestaurant from '../../../hooks/useRestaurant';
import DynamicIcon from '../../shared/DynamicIcon';
import CheckboxElement from '../../shared/FormElements/CheckboxElement';
import RadioElement from '../../shared/FormElements/RadioElement';
import PriceBubbleElement from './Elements/PriceBubbleElement';
import QuantitySelectorElement from './Elements/QuantitySelectorElement';
import ExtrasVariations from './Variations/ExtrasVariations';

const RestaurantItemModal = ({ open, close, onClick, item }) => {
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="transition duration-500 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-500 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex my-4 h-[95%] justify-center text-center items-center">
            <Transition.Child
              as={Fragment}
              className="transition duration-700 lg:w-3/5 xl:w-2/5 sm:w-4/5 w-11/12 min-w-2/5 overflow-scroll max-h-full h-fit rounded-3xl"
              enter="ease-in-out"
              enterFrom="translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="ease-out"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="translate-y-full opacity-0">
              <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8">
                <ItemBody close={close} item={item} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RestaurantItemModal;

const ItemBody = ({ close, item }) => {
  const [quantity, setQuantity] = useState(1);
  const [productVersions, setProductVersions] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [extraList, setExtraList] = useState([]);
  const [price, setPrice] = useState(0);

  const { cartItems, setStoredCart, setCart, setStoredCartItems, setLoading } = useCart();
  const { restaurantId } = useRestaurant();

  useEffect(() => {
    if (item) {
      console.log(item);
      let versions = item?.ProductVersions;

      versions.forEach((version, index) => {
        if (index === 0) {
          setSelectedVersion(version);
        }
      });

      setProductVersions(versions);
    }

    // console.log(orderItems);
  }, [item]);

  useEffect(() => {
    if (selectedVersion) {
      calculatePrice();
    }
  }, [selectedVersion, extraList, quantity]);

  const selectProductVersion = (item) => {
    setExtraList([]); // reset extras when changing base version

    let items = [...productVersions];

    items.forEach((x) => {
      setSelectedVersion(null);
    });

    items.forEach((x) => {
      if (x.ID === item.ID) {
        setSelectedVersion(x);
      }
    });

    setProductVersions(items);
  };

  const addToExtra = (extra) => {
    // Add the new extra to the end of the list
    setExtraList([...extraList, extra]);
  };

  const addToExtraInit = (extra) => {
    // Add the new extra to the end of the list
    extraList.push(extra);
  };

  const removeFromExtra = (extraToRemove, extraToAdd) => {
    // First, create a new array that does not include the itemToRemove
    const updatedArray = extraList.filter((item) => item !== extraToRemove);

    // Then, add the new item to the end of the array
    updatedArray.push(extraToAdd);

    // Finally, update the extraList state with the updated array
    setExtraList(updatedArray);
  };

  const verifyItems = async (items) => {
    setLoading(true);
    let storedItems;
    if (cartItems?.length) {
      storedItems = JSON.parse(cartItems);
      console.log('CART ITREMSSSALKSN', storedItems.OrderItems);
    }

    const cart = {
      RestaurantID: restaurantId,
      OrderItems: cartItems?.length ? storedItems?.OrderItems : items,
      Add: cartItems?.length ? items : null
    };

    try {
      const response = await axiosPublic('VerifyOrderItems', '', cart);
      console.log('VERIFIED', response);
      const cartSave = JSON.stringify(response.data?.Data);
      setStoredCartItems(response.data?.Data?.OrderItemsJson);
      setStoredCart(cartSave);
      setLoading(false);
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      setLoading(false);
    }

    // setCart(response.data?.Data);
  };

  const addItemToCart = () => {
    let orderItem;
    const orderItems = [];

    // First, add selected product version ID to orderItem
    orderItem = {
      Group: '',
      ProductID: item.ID,
      ProductVersionID: selectedVersion.ID,
      Quantity: quantity
    };

    // then create a new array of ExtraList that holds ID's of each item in extraList
    const ExtraList = extraList.map((x) => x.ID);

    // Then, add selected extras to orderItem
    orderItem.ExtraList = ExtraList;

    console.log(orderItem);

    // Finally, add the orderItem to orderItems state
    // setOrderItems([...orderItems, orderItem]);
    orderItems.push(orderItem);

    verifyItems(orderItems);

    close();
  };

  const changeQuantity = (change) => {
    let count = quantity;
    count += change;
    setQuantity(count);
  };

  const calculatePrice = () => {
    let total = 0;

    extraList.forEach((x) => {
      total += x.Price;
    });

    total += selectedVersion?.Price;
    const totalPrice = (total * quantity).toFixed(2);

    console.log('EXTRA LIST', extraList);
    setPrice(totalPrice);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full mx-auto h-11/12 static">
        <div className="bg-white flex items-center rounded-t-3xl justify-between p-3 space-x-5 sticky top-0 overflow-scroll overscroll-contain w-full shadow-sm">
          <h1 className="text-2xl text-left w-10/12">{item.Name}</h1>
          <div
            onClick={close}
            tabIndex="0"
            className="flex h-10 w-10 items-center justify-center rounded-full text-gray-400 bg-gray-200 hover:text-primary cursor-pointer transition-all ease-in duration-200 ring-0 outline-none">
            <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
          </div>
        </div>
        {item.Image ? (
          <img src={`https://dk.takeoutresource.net/uploads/images/750x750/${item.Image}`} alt="restaurant_item" className="w-full h-80 object-cover" />
        ) : (
          <img src={noImage} alt="menu_item" className="w-full h-60 object-contain" />
        )}
        <div className="p-3 w-full">
          <div className="text-left pb-2 max-w-[500px]">{parse(item.Description)}</div>
          <div className="border-b my-1 w-full"></div>
          <div className="my-2">
            {productVersions?.length > 1 && (
              <div className="bg-gray-100 font-medium w-full p-3 mt-3">
                <h2 className="text-center text-dark">Version</h2>
              </div>
            )}
            {productVersions?.map((version) => (
              <div key={version.ID}>
                <>
                  {productVersions?.length > 1 && (
                    <div className="">
                      <div className="flex items-center justify-between my-2">
                        <RadioElement
                          name="version"
                          checked={selectedVersion?.ID === version.ID}
                          onChange={() => selectProductVersion(version)}
                          labelText={version.Name ? version.Name : 'Base'}
                        />
                        <div className="text-gray-500">{version.Price} DKK</div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            ))}
          </div>

          {selectedVersion?.ExtraGroups.length > 0 &&
            selectedVersion?.ExtraGroups.map((group) =>
              group.Type === 1 ? (
                <MultipleSelectorGroup key={group.ID} group={group} addExtra={setExtraList} />
              ) : (
                <SingleSelectorGroup
                  key={group.ID}
                  extraGroup={group}
                  addExtra={addToExtra}
                  addExtraInit={addToExtraInit}
                  removeFromExtra={removeFromExtra}
                  extraList={extraList}
                />
              )
            )}
        </div>

        <div className="bg-transparent flex space-x-5 h-12 min-h-fit sticky px-3 bottom-2 mt-5">
          <QuantitySelectorElement quantity={quantity} add={() => changeQuantity(1)} subtract={() => changeQuantity(-1)} />
          <PriceBubbleElement price={price} onClick={addItemToCart} />
        </div>
      </div>
    </>
  );
};

const SingleSelectorGroup = ({ extraGroup, addExtra, removeFromExtra, extraList, addExtraInit }) => {
  const [group, setGroup] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    let _group = extraGroup;

    setGroup(_group);

    if (group && _group.MinimumSelectionLimit > 0) {
      selectExtraInit(_group?.Extras[0]);
    }
  }, [group]);

  // set selected item
  const selectExtra = (extra) => {
    // Check if the extra is already in the list
    const isExtraInList = extraList.some((item) => item.ID === selectedItem?.ID);

    if (isExtraInList) {
      // If the extra is already in the list, remove it and add the new one
      console.log('it is ');
      removeFromExtra(selectedItem, extra);
    } else {
      // If the extra is not in the list, add it
      addExtra(extra);
    }
    setSelectedItem(extra);
  };

  const selectExtraInit = (extra) => {
    setSelectedItem(extra);
    addExtraInit(extra);
  };

  return (
    <>
      {extraGroup && (
        <div className="">
          <div className="flex flex-col items-baseline justify-between my-2">
            <div className="bg-gray-100 font-medium w-full p-3 mt-3">
              <h2 className="text-center text-dark">{extraGroup.Name}</h2>
              <div className="text-xs text-dark">
                {extraGroup.MinimumSelectionLimit > 0
                  ? `(Pick at least ${extraGroup.MinimumSelectionLimit} and max. ${extraGroup.SelectionLimit})`
                  : `(Max. ${extraGroup.SelectionLimit})`}
              </div>
            </div>
          </div>

          {extraGroup.Extras.map((extra) => (
            <div className="mb-2" key={extra.ID}>
              <div className="flex items-center justify-between my-2">
                <RadioElement key={extra.ID} labelText={extra.Name} checked={selectedItem?.ID === extra.ID} onChange={() => selectExtra(extra)} />
                <div className="text-gray-500 w-2/12 text-right">{extra.Price > 0 ? extra.Price + ' DKK' : 'Free'}</div>
                {/* <div className="text-gray-500 w-2/12 text-right">{selectedItem?.ID === extra.ID ? 'selected' : 'Free'}</div> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const MultipleSelectorGroup = ({ group, addExtra }) => {
  const [selectedExtras, setSelectedExtras] = useState([]);

  useEffect(() => {
    console.log('ITEM', selectedExtras);
  }, [selectedExtras]);

  const handleCheckboxChange = (extra) => {
    let _selectedExtras = [...selectedExtras];

    if (_selectedExtras.some((x) => x.ID === extra.ID)) {
      _selectedExtras = _selectedExtras.filter((x) => x.ID !== extra.ID);
    } else {
      _selectedExtras.push(extra);
    }

    setSelectedExtras(_selectedExtras);
    addExtra(_selectedExtras);
  };

  return (
    <div key={group.ID} className="">
      <div className="mb-2">
        <div className="flex flex-col items-baseline justify-between my-2">
          <div className="bg-gray-100 font-medium w-full p-3 mt-3">
            <h2 className="text-center text-dark">{group.Name}</h2>
            <div className="text-xs text-dark">
              {group.MinimumSelectionLimit > 0
                ? `(Pick at least ${group.MinimumSelectionLimit} and max. ${group.SelectionLimit})`
                : `(Max. ${group.SelectionLimit})`}
            </div>
          </div>
        </div>
      </div>

      {group.Extras.map((extra) => (
        <div className="mb-2" key={extra.ID}>
          <div className="flex items-center justify-between my-2">
            <CheckboxElement
              key={extra.ID}
              labelText={extra.Name}
              value={extra.ID}
              checked={selectedExtras.some((x) => x.ID === extra.ID)}
              disabled={selectedExtras.length >= group?.SelectionLimit && !selectedExtras.includes(extra)}
              onChange={() => handleCheckboxChange(extra)}
            />
            <div
              className={`${
                selectedExtras.length >= group?.SelectionLimit && !selectedExtras.includes(extra) ? 'text-gray-300' : 'text-gray-500'
              } w-2/12 text-right`}>
              {extra.Price > 0 ? extra.Price + ' DKK' : 'Free'}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
