import React from 'react';

const RestaurantMenuItemSkeleton = () => {
  return (
    <div className="rounded-3xl px-6 py-3 w-full mx-auto bg-white h-40 mb-4">
      <div className="animate-pulse flex space-x-10 w-full items-center">
        <div className="flex-1 space-y-5 py-1">
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              <div className="h-2 bg-gray-300 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-300 rounded"></div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              <div className="h-2 bg-gray-300 rounded col-span-1"></div>
            </div>
          </div>
          <div className="h-7 w-20 bg-gray-300 rounded-full"></div>
        </div>
        <div className="rounded-3xl bg-gray-300 h-28 w-28"></div>
      </div>
    </div>
  );
};

export default RestaurantMenuItemSkeleton;
