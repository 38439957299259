import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axiosPublic from '../api/axiosPublic';

// API CALLS
const getRestaurant = async ({ queryKey }) => {
  const restaurantId = queryKey[1];
  const response = await axiosPublic('GetRestaurant', '', { ID: restaurantId });
  console.log('RESTAURANT', response.data?.Data);
  return response;
};

const getMenucard = async ({ queryKey }) => {
  const restaurantId = queryKey[1];
  const response = await axiosPublic('GetMenucard', '', { RestaurantID: restaurantId });
  console.log(response.data.Data);
  return response;
};

// QUERIES
export const useGetRestaurant = (onSuccess, onError, restaurantId) => {
  return useQuery({ queryKey: ['getRestaurant', restaurantId], queryFn: getRestaurant, onSuccess, onError, enabled: !!restaurantId });
};

export const useGetMenucard = (restaurantId) => {
  return useQuery({ queryKey: ['getMenucard', restaurantId], queryFn: getMenucard, enabled: !!restaurantId });
};
