import { useEffect, useState } from 'react';

import useAddress from '../../../hooks/useAddress';
import useOrder from '../../../hooks/useOrder';
import Heading from '../../shared/Heading';
import DiscountSection from './Sections/DiscountSection';
import PaymentSection from './Sections/PaymentSection';
import TimeSection from './Sections/TimeSection';
import UserInformationSection from './Sections/UserInformationSection';

const DeliveryDetails = ({ mode }) => {
  const { getStoredAddress } = useAddress();

  const storedAddress = getStoredAddress();

  return (
    <div className="flex flex-col h-full mt-5 space-y-10">
      <div>
        <Heading text="Delivery Information" />
        <UserInformationSection mode={mode} />
      </div>
      {mode === 'Delivery' && storedAddress?.length > 0 ? (
        <>
          <hr className="w-full mx-auto my-5 border bg-dark border-dark" />
          <div>
            <Heading text="Choose Time" />
            <TimeSection mode={mode} />
          </div>
          <hr className="w-full mx-auto my-5 border bg-dark border-dark" />

          <div>
            <Heading text="Payment Type" />
            <PaymentSection />
          </div>
          <hr className="w-full mx-auto my-5 border bg-dark border-dark" />
          <div>
            <Heading text="Discount Code" />
            <DiscountSection />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DeliveryDetails;
