import parse from 'html-react-parser';

import noImage from '../../../assets/images/no_image.png';
import PriceBubble from '../../shared/PriceBubble';

const RestaurantMenuItem = ({ onClick, name, description, image, price }) => {
  const formatDescription = (description) => {
    const parsedDescription = parse(description);
    if (parsedDescription.length > 105) {
      return parsedDescription.slice(0, 105) + '...';
    }
    return parsedDescription;
  };

  return (
    <div
      onClick={onClick}
      className="bg-white px-6 py-3 rounded-3xl flex shadow-md justify-between items-center mb-4 hover:scale-[1.03] transition-all duration-500 cursor-pointer hover:opacity-95">
      <div className="flex flex-col justify-between w-3/4 h-full">
        <h1 className="text-xl text-dark">{name}</h1>
        <div className="mb-3 text-gray-600"> {formatDescription(description)}</div>
        <PriceBubble price={price} currency="dkk" small />
      </div>
      <div className="">
        {image ? (
          <img src={`https://dk.takeoutresource.net/uploads/images/240x240/${image}`} alt="menu_item" className="object-cover w-28 h-28 rounded-3xl" />
        ) : (
          <img src={noImage} alt="menu_item" className="object-cover w-28 h-28 rounded-3xl" />
        )}
      </div>
    </div>
  );
};

export default RestaurantMenuItem;
