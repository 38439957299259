import { useState } from 'react';

import DynamicIcon from '../../../shared/DynamicIcon';

const PaymentSection = () => {
  const [paymentMethod, setPaymentMethod] = useState('cash');

  return (
    <div className="mt-4">
      <ul className="grid w-full gap-6 md:grid-cols-2">
        <li>
          <input
            onChange={(e) => setPaymentMethod(e.target.value)}
            type="radio"
            id="cash"
            name="hosting"
            checked={paymentMethod === 'cash'}
            value="cash"
            className="hidden peer"
            required
          />
          <label
            htmlFor="cash"
            value="cash"
            className="inline-flex items-center justify-between w-full p-5 bg-white border-2 rounded-lg cursor-pointer border-dark text-dark peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-50">
            <div className="flex items-center justify-between w-full">
              <div className="block">
                <div className="w-full text-lg font-semibold">Cash</div>
              </div>
              <DynamicIcon icon="BanknotesIcon" />
            </div>
          </label>
        </li>
        <li>
          <input
            onChange={(e) => setPaymentMethod(e.target.value)}
            checked={paymentMethod === 'card'}
            type="radio"
            id="card"
            name="hosting"
            value="card"
            className="hidden peer"
          />
          <label
            htmlFor="card"
            value="card"
            className="inline-flex items-center justify-between w-full p-5 bg-white border-2 rounded-lg cursor-pointer border-dark text-dark peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-50">
            <div className="flex items-center justify-between w-full">
              <div className="block">
                <div className="w-full text-lg font-semibold">Credit card</div>
              </div>
              <DynamicIcon icon="CreditCardIcon" />
            </div>
          </label>
        </li>
      </ul>
      {paymentMethod === 'card' && <CardPayment />}
    </div>
  );
};

export default PaymentSection;

const CardPayment = () => {
  return (
    <div className="mt-10">
      <div className="grid w-full grid-cols-12 gap-4">
        <div className="flex flex-col w-full col-span-12">
          <label htmlFor="cardNumber" className="text-sm font-semibold text-dark">
            Card number
          </label>
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            className="w-full px-3 py-3 mt-1 text-left placeholder-gray-500 border-2 border-dark rounded-xl text-dark focus:ring-0 focus:ring-primary focus:border-primary focus:z-10 text-md"
            placeholder="Card number"
            required
          />
        </div>
        <div className="flex flex-col w-full col-span-12">
          <label htmlFor="cardName" className="text-sm font-semibold text-dark">
            Name on card
          </label>
          <input
            type="text"
            id="cardName"
            name="cardName"
            className="w-full px-3 py-3 mt-1 text-left placeholder-gray-500 border-2 border-dark rounded-xl text-dark focus:ring-0 focus:ring-primary focus:border-primary focus:z-10 text-md"
            placeholder="Name on card"
            required
          />
        </div>
        <div className="flex flex-col w-full col-span-6">
          <label htmlFor="cardExpiry" className="text-sm font-semibold text-dark">
            Expiry date
          </label>
          <input
            type="text"
            id="cardExpiry"
            name="cardExpiry"
            className="w-full px-3 py-3 mt-1 text-left placeholder-gray-500 border-2 border-dark rounded-xl text-dark focus:ring-0 focus:ring-primary focus:border-primary focus:z-10 text-md"
            placeholder="MM/YY"
            required
          />
        </div>
        <div className="flex flex-col w-full col-span-6">
          <label htmlFor="cardCVC" className="text-sm font-semibold text-dark">
            CVC
          </label>
          <input
            type="text"
            id="cardCVC"
            name="cardCVC"
            className="w-full px-3 py-3 mt-1 text-left placeholder-gray-500 border-2 border-dark rounded-xl text-dark focus:ring-0 focus:ring-primary focus:border-primary focus:z-10 text-md"
            placeholder="CVC"
            required
          />
        </div>
      </div>
    </div>
  );
};
