import DynamicIcon from './DynamicIcon';

const ScrollToTop = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      onClick={goToTop}
      tabIndex="0"
      className="fixed right-5 bottom-5 flex h-10 w-10 items-center justify-center rounded-full text-dark bg-gray-200 hover:text-primary cursor-pointer transition-all ease-in duration-200">
      <DynamicIcon icon="ChevronUpIcon" width="w-7" height="h-7" aria-hidden="true" />
    </div>
  );
};

export default ScrollToTop;
