import { useNavigate } from 'react-router-dom';

import useCart from '../../hooks/useCart';
import useRestaurant from '../../hooks/useRestaurant';
import AppButton from '../shared/AppButton';
import DynamicIcon from '../shared/DynamicIcon';
import PriceBubble from '../shared/PriceBubble';
import CheckoutSkeleton from '../Skeletons/CheckoutSkeleton';

const RestaurantCheckoutCard = () => {
  const { restaurant } = useRestaurant();
  const { getStoredCart, incrementItem, decrementItem, removeItemFromCart, loading } = useCart();

  const navigate = useNavigate();

  const goToCheckout = () => {
    navigate('/checkout');
    // else if (restaurant.closed) {
    //     showAlert("Midlertidigt utilgængelig!")
    // }
  };

  const increment = (item) => {
    console.log(item);
    incrementItem(item);
  };

  const decrement = (item) => {
    console.log(item);
    decrementItem(item);
  };

  const removeItem = (item) => {
    removeItemFromCart(item);
  };

  const cart = getStoredCart();

  return (
    <div className="bg-white rounded-2xl px-4 py-4 mt-2 space-y-2 flex flex-col items-center sticky top-2 h-fit max-h-[600px]">
      <div className="text-xl font-medium text-dark">Your Order</div>
      {!cart ? (
        <div className="border-dotted border-2 px-2 py-5 border-dark w-full">
          <p className="text-center text-gray-600">
            {restaurant.DeliveryMinLimit
              ? `Minimal order for delivery in this restaurant is ${restaurant.DeliveryMinLimit?.toFixed(2)} DKK`
              : 'Your cart is empty'}
          </p>
        </div>
      ) : (
        <>
          {loading ? (
            <CheckoutSkeleton />
          ) : (
            <>
              <PriceBubble price={cart?.ItemFinal} currency="kr." />
              <div className="flex flex-col items-center w-full h-4/5 overflow-y-scroll overscroll-contain">
                {cart?.OrderItems.map((item, index) => (
                  <div className="w-full bg-gray-100 rounded-2xl p-3 mt-4" key={index}>
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex ">
                          <div className="w-1/12 self-baseline text-black cursor-pointer hover:opacity-95 ">
                            <DynamicIcon onClick={() => removeItem(item)} icon="XMarkIcon" width="w-5" height="h-5" />
                          </div>
                          <div className="text-sm w-8/12 mr-0.5">
                            {/* x{item.Quantity}  */}
                            {item.ProductName} {item.ProductVersionName && item.ProductVersionName}
                          </div>
                          <div className="rounded-xl w-3/12 bg-white flex px-2 items-center justify-center self-baseline">
                            <div className="hover:cursor-pointer hover:scale-125 transition-all duration-200">
                              <DynamicIcon onClick={() => decrement(item)} icon="MinusIcon" width="w-4" height="h-4" />
                            </div>
                            <div className="mx-1">{item.Quantity}</div>
                            <div className="hover:cursor-pointer hover:scale-125 transition-all duration-200">
                              <DynamicIcon onClick={() => increment(item)} icon="PlusIcon" width="w-4" height="h-4" />
                            </div>
                          </div>
                        </div>

                        <div className="self-baseline ml-5">
                          {item.Extras.length > 0 &&
                            item.Extras.map((extra, index) => (
                              <div className="flex flex-col" key={index}>
                                <div className="text-xs text-gray-500">
                                  + {extra.Name} ({extra.Price.toFixed(2)} kr.)
                                </div>
                              </div>
                            ))}
                          <div className="text-sm font-medium text-dark mt-4">{item.PriceWithExtras.toFixed(2)} kr.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {cart && (
        <div className="w-4/5 pt-4">
          <AppButton text="Checkout" rounded textColor="text-white" textSize="text-lg" width="w-full" onClick={goToCheckout} />
        </div>
      )}
    </div>
  );
};

export default RestaurantCheckoutCard;
