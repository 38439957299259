import { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

const AppModal = ({ close, open, children }) => {
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="transition duration-500 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-500 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              className="transition duration-500"
              enter="ease-in-out"
              enterFrom="translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="ease-out"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="translate-y-full opacity-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-lg">
                {children}
                {/* <div className="flex flex-col bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-3xl">
                  <div className={`${type !== 'login' ? 'justify-between' : 'justify-end'} flex w-full`}>
                    {type !== 'login' && (
                      <div className="flex items-center cursor-pointer hover:opacity-90" onClick={() => setType('login')}>
                        <div className="flex h-12 w-full self-end items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 text-primary bg-gray-50 hover:bg-gray-100 cursor-pointer transition-all ease-in duration-200">
                          <DynamicIcon icon="ChevronLeftIcon" width="w-8" height="h-8" aria-hidden="true" />
                        </div>
                        <span className="ml-2 text-dark text-md">Log in</span>
                      </div>
                    )}
                    <div
                      onClick={close}
                      className="flex h-12 w-full self-end items-center justify-center rounded-full text-primary bg-gray-50 sm:mx-0 sm:h-10 sm:w-10 hover:bg-gray-100 cursor-pointer transition-all ease-in duration-200">
                      <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    {type === 'login' ? <LoginForm switchType={() => setType('register')} /> : <RegisterForm switchType={() => setType('login')} />}
                  </div>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AppModal;
