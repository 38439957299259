import { useContext, useEffect, useState } from 'react';

import { Element } from 'react-scroll';

import RestaurantCategoryCard from '../../components/Restaurant/Menu/RestaurantCategoryCard';
import RestaurantCategoryList from '../../components/Restaurant/Menu/RestaurantCategoryList';
import RestaurantItemModal from '../../components/Restaurant/Menu/RestaurantItemModal';
import RestaurantMenuItem from '../../components/Restaurant/Menu/RestaurantMenuItem';
import ScrollToTop from '../../components/shared/ScrollToTop';
import RestaurantCategorySkeleton from '../../components/Skeletons/RestaurantCategorySkeleton';
import RestaurantMenuSkeleton from '../../components/Skeletons/RestaurantMenuSkeleton';
import RestaurantContext from '../../contexts/RestaurantContext';
import { useGetMenucard } from '../../hooks/useRestaurantQuery';

const RestaurantMenu = () => {
  const [itemModal, setItemModal] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { restaurantId } = useContext(RestaurantContext);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    });
  }, []);

  const toggleItemModal = () => {
    setItemModal(!itemModal);
  };

  //TODO: HANDLE ERROR STATE
  const { data, isLoading, isError } = useGetMenucard(restaurantId);

  return (
    <>
      {/* <div className="block md:hidden"> */}
      <RestaurantCategoryList categories={data?.data?.Data?.Categories} />
      {/* </div> */}
      <div className="flex flex-row w-full mb-2">
        <div className="hidden md:w-1/4 md:block">
          {isLoading ? <RestaurantCategorySkeleton /> : <RestaurantCategoryCard categories={data?.data?.Data?.Categories} />}
        </div>
        <div className="hidden mx-4 my-2 mb-10 bg-white border border-white md:block" />
        <div className="flex flex-col items-center w-full md:w-3/4">
          {isLoading
            ? [1, 2, 3, 4].map((item) => {
                return <RestaurantMenuSkeleton key={item} />;
              })
            : data?.data?.Data?.Categories?.map((category) => {
                return (
                  <div className="w-full" key={category.ID}>
                    <Element name={category.Name}>
                      <div className="w-full mt-5 mb-2 text-xl italic text-center text-dark">{category.Name}</div>

                      {category.Products.map((item) => {
                        return (
                          <RestaurantMenuItem
                            key={item.ID}
                            name={item.Name}
                            description={item.Description}
                            price={item.PriceMinimum}
                            image={item.Image}
                            onClick={() => {
                              toggleItemModal();
                              setSelectedItem(item);
                            }}
                          />
                        );
                      })}
                    </Element>
                  </div>
                );
              })}
        </div>

        {showScrollToTop && <ScrollToTop />}

        <div className="hidden mx-4 my-2 mb-10 bg-white border border-white lg:block" />
        {itemModal && <RestaurantItemModal open={itemModal} close={toggleItemModal} item={selectedItem} />}
      </div>
    </>
  );
};

export default RestaurantMenu;
