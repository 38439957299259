import { useEffect, useState } from 'react';

import useAddress from '../../../../hooks/useAddress';
import useAuth from '../../../../hooks/useAuth';
import useOrder from '../../../../hooks/useOrder';
import InputElement from '../../../shared/FormElements/InputElement';
import SearchAddressElement from '../../../shared/FormElements/SearchAddressElement';
import TextareaElement from '../../../shared/FormElements/TextareaElement';

const UserInformationSection = ({ mode }) => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [floorAndFlat, setFloorAndFlat] = useState('');

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setFullName(user?.Name);
      setPhoneNumber(user?.Phone);
      setEmail(user?.Email);
    }
  }, [user]);

  const validateOrder = async () => {
    // setLoading(true);
    try {
      // const response = await axiosPublic('VerifyOrderItems', '', cart);
      // console.log('VERIFIED', response);
      // const cartSave = JSON.stringify(response.data?.Data);
      // if (!response.data?.Data?.OrderItems.length) {
      //   clearState();
      // } else {
      // setStoredCartItems(response.data?.Data?.OrderItemsJson);
      // setStoredCart(cartSave);
      // }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
      // toast.error('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="grid grid-cols-12 mt-4 gap-x-2 gap-y-6">
      <div className="flex flex-col col-span-6">
        <InputElement labelText="Full Name*" name="fullName" placeholder="John Doe" required value={fullName} onChange={(e) => setFullName(e.target.value)} />
      </div>
      <div className="flex flex-col col-span-6">
        <InputElement
          labelText="Phone Number*"
          name="phoneNumber"
          placeholder="Phone number*"
          type="number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      {mode === 'Delivery' && (
        <>
          <div className="flex flex-col col-span-6">
            <SearchAddressElement labelText="Delivery Location*" placeholder="Your location" address={address} setAddress={setAddress} />
          </div>
          <div className="flex flex-col col-span-6">
            <InputElement
              labelText="Floor and flat nr."
              name="floorAndFlat"
              placeholder="Your floor and flat nr."
              type="text"
              value={floorAndFlat}
              onChange={(e) => setFloorAndFlat(e.target.value)}
            />
          </div>
        </>
      )}
      <div className="flex flex-col col-span-12">
        <TextareaElement labelText="Comments" placeholder="Your comments..." />
      </div>
    </div>
  );
};

export default UserInformationSection;
