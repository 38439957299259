import React from 'react';

const UserInfoCard = ({ title, children }) => {
  return (
    <div className="bg-white border-dark border-2 p-4 rounded-lg w-full md:w-1/2">
      <h1 className="text-2xl font-bold text-dark">{title}</h1>
      <div className="mt-3">{children}</div>
    </div>
  );
};

export default UserInfoCard;
