import React from 'react';

const CheckoutSkeleton = () => {
  return (
    <div className="rounded-3xl px-6 py-3 w-full mx-auto bg-white h-fit mb-4">
      <div className="animate-pulse flex space-x-10 w-full items-center">
        <div className="flex flex-col flex-1 space-y-3 py-1">
          <div className="h-12 w-1/2 bg-gray-300 rounded-full mx-auto"></div>
          <div className="h-24 bg-gray-300 rounded-2xl"></div>
          <div className="h-24 bg-gray-300 rounded-2xl"></div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSkeleton;
