import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axiosPrivate from '../api/axiosPrivate';

// API CALLS
const getUser = async ({ queryKey }) => {
  const token = queryKey[1];
  const response = await axiosPrivate('GetUser', token, '');
  console.log('USER', response);
  return response;
};

const updateUser = async (userData) => {
  console.log('DATA', userData.token, userData.data);
  const response = await axiosPrivate('UpdateUser', userData.token, userData.data);
  console.log('response', response);
};

// QUERIES
export const useGetUser = (onSuccess, onError, token) => {
  return useQuery({ queryKey: ['getUser', token], queryFn: getUser, onSuccess, onError, enabled: !!token });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUser');
    }
  });
};
