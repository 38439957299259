import { Link } from 'react-scroll';

const RestaurantCategoryList = ({ categories }) => {
  const active = 'font-bold text-secondary';

  return (
    <>
      {categories?.length > 0 ? (
        <div className="bg-white rounded-full sticky top-2 md:hidden block z-50 shadow-md px-2 py-4 whitespace-nowrap my-2">
          <div className="space-x-4 flex overflow-x-scroll">
            {categories?.map((category) => {
              return (
                <div key={category.ID} className="text-dark ml-2 my-1 cursor-pointer hover:text-secondary hover:opacity-90 w-full">
                  <Link activeClass={active} to={category.Name} spy={true} smooth={true} duration={1000}>
                    {category.Name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RestaurantCategoryList;
