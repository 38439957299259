import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAddress from '../../hooks/useAddress';
import useAuth from '../../hooks/useAuth';
import useCart from '../../hooks/useCart';
import useOrder from '../../hooks/useOrder';
import AppButton from '../shared/AppButton';

const CheckoutSummary = () => {
  const { currentAddress } = useAddress();
  const { getStoredCart } = useCart();
  const { mode, selectedTimeOption } = useOrder();
  const { token } = useAuth();
  const navigate = useNavigate();

  const cart = getStoredCart();

  useEffect(() => {
    if (!cart) {
      toast.warn('Cart is empty');
      navigate('/restaurant');
    }
    if (!token) {
      toast.warn('Please login to continue');
      navigate('/restaurant');
    }
    console.log('CART', cart);
  }, [cart, token]);

  return (
    <div className="sticky w-full p-5 bg-white shadow-md rounded-3xl min-h-fit top-2">
      <h1 className="text-2xl">Your Basket</h1>
      {cart?.OrderItems.map((item, index) => (
        <div className="flex flex-col mt-4 text-dark" key={index}>
          <div className="flex items-center justify-between w-full">
            <div>
              {' '}
              {item.Quantity}x {item.ProductName}
            </div>
            <div>{item.PriceWithExtras.toFixed(2)} kr.</div>
          </div>
          <div className="ml-2">
            {item.Extras.length > 0 &&
              item.Extras.map((extra, index) => (
                <div className="flex text-[14px] font-light text-gray-500 justify-between" key={index}>
                  <div>+ {extra.Name}</div>
                  <div>{extra.Price.toFixed(2)} kr.</div>
                </div>
              ))}
          </div>
        </div>
      ))}

      {/* TODO: make sure the prices are correct*/}
      <hr className="my-5 w-full mx-auto border-0.5 bg-gray-300 border-gray-300" />
      <div className="flex flex-col">
        <SubtotalLine text="Subtotal" price={cart?.ItemFinal.toFixed(2)} />
        <SubtotalLine text="Bag Fee" price={cart?.SurchargeDelivery.toFixed(2)} />
        {/* {mode === 'Delivery' && <SubtotalLine text="Delivery Fee" price={cart?.DeliveryFinal.toFixed(2)} />} */}
      </div>
      <div className="flex items-center justify-between mt-5">
        <div className="text-lg font-medium">Total</div>
        <div className="text-lg font-medium">{cart?.TotalForDelivery.toFixed(2)} kr.</div>
      </div>

      <hr className="my-5 w-full mx-auto border-0.5 bg-gray-300 border-gray-300" />
      <div className="mt-5">
        {(mode === 'Delivery' && !currentAddress) || !selectedTimeOption ? (
          <AppButton text="All required information must be selected" rounded width="w-full" disabled />
        ) : (
          <AppButton text="Place order" rounded width="w-full" onClick={() => navigate('/receipt')} />
        )}
      </div>
    </div>
  );
};

export default CheckoutSummary;

const SubtotalLine = ({ text, price }) => {
  return (
    <div className="flex items-center justify-between mb-1 text-dark">
      <div>{text}</div>
      <div>{price} kr.</div>
    </div>
  );
};
