const CheckboxElement = ({ name, value, checked, disabled, onChange, labelText }) => {
  return (
    <div className="flex items-center">
      <input
        id={name}
        name={name}
        type="checkbox"
        className={`${
          disabled ? 'text-gray-300 border-gray-300' : 'text-primary border-gray-400'
        } h-5 w-5 rounded-md border-2 bg-transparent  focus:ring-0 hover:cursor-pointer`}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={name} className={`${disabled ? 'text-gray-300' : 'text-dark'} ml-3 text-left block text-md`} onClick={onChange}>
        {labelText}
      </label>
    </div>
  );
};

export default CheckboxElement;
