import { createContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import axiosPublic from '../api/axiosPublic';

const RestaurantContext = createContext({});

export const RestaurantProvider = ({ children }) => {
  const RESTAURANT_LOCALSTORAGE_KEY = 'current_restaurant';
  const localStorage = window.localStorage;

  const [restaurant, setRestaurant] = useState('');
  const [restaurantId, setRestaurantId] = useState(localStorage.getItem(RESTAURANT_LOCALSTORAGE_KEY) || '');
  const [company, setCompany] = useState(null);
  const [companyID, setCompanyID] = useState(null);

  // 451 - amager - no images
  // 193 - 7eleven
  // 442 - burger beast
  // 138 - couloir
  // 380 - currybox

  // 38 - kulinaris
  // 22 - bindia - no active
  // 119 - nice images
  // 23 - Cows & cocks many selections

  useEffect(() => {
    getCompanyId();
  }, []);

  const url = window.location.href;
  const urlParts = url.split('/');
  const domain = urlParts[2];

  const getCompanyId = async () => {
    try {
      console.log(url);
      const response = await axios.get(`https://api.waiteer.com/api/places/takeout/domain?domain=${domain}`);
      // const response = await axios.get(`https://api.waiteer.com/api/places/takeout/domain?domain=www.7eleven.sraczka.com`);

      console.log(response);
      setCompanyID(response.data.TakeoutId);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async () => {
    // const response = await axiosPublic('GetCompany', '', { ID: 38 });
    const response = await axiosPublic('GetCompany', '', { ID: companyID });
    console.log('COMPANY', response.data.Data);
    setCompany(response.data?.Data);
    const colors = JSON.parse(response.data?.Data?.Colors);
    // console.log(colors.ColorPrimary);
    document.documentElement.style.setProperty('--color-primary', colors.ColorPrimary);
    document.documentElement.style.setProperty('--color-secondary', colors.ColorSecondary);
    document.title = response.data?.Data.Name;
    return response;
  };

  const { data: comp } = useQuery({ queryKey: ['getCompany'], queryFn: getCompany, staleTime: Infinity, enabled: !!companyID });

  const setCurrentRestaurant = (restaurantId) => {
    localStorage.setItem(RESTAURANT_LOCALSTORAGE_KEY, restaurantId);
    setRestaurantId(restaurantId);
  };

  const clearCurrentRestaurant = () => {
    localStorage.removeItem(RESTAURANT_LOCALSTORAGE_KEY);
    setRestaurantId('');
  };

  return (
    <RestaurantContext.Provider
      value={{ restaurant, setRestaurant, company, setCompany, setRestaurantId, restaurantId, setCurrentRestaurant, clearCurrentRestaurant }}>
      {children}
    </RestaurantContext.Provider>
  );
};

export default RestaurantContext;
