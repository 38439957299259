import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

import convertTime from '../../helpers/convertTime';

const RestaurantHeader = ({ restaurant }) => {
  const renderStatus = (time) => {
    const currentTime = dayjs();
    const timeRange = convertTime(time);
    // const timeRange = '18:00 - 22:00';

    // Parse the start and end times from the time range string
    const [startTimeString, endTimeString] = timeRange.split(' - ');
    const startTime = dayjs(startTimeString, 'HH:mm');
    const endTime = dayjs(endTimeString, 'HH:mm');

    if (time === '') {
      return (
        <>
          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
          <p>Closed today</p>
        </>
      );
    } else if (currentTime.isBefore(startTime)) {
      return (
        <>
          <div className="w-2 h-2 bg-yellow-600 rounded-full"></div>
          <p>
            {' '}
            Open later: {startTime.format('HH:mm')} - {endTime.format('HH:mm')}
          </p>
        </>
      );
    } else if (currentTime.isAfter(endTime)) {
      return (
        <>
          <div className="w-2 h-2 bg-red-500 rounded-full"></div>
          <p> Closed for today</p>
        </>
      );
    } else {
      return (
        <>
          <div className="w-2 h-2 bg-green-500 rounded-full"></div>
          <p> Open until: {endTime.format('HH:mm')}</p>
        </>
      );
    }
  };

  return (
    <div className="relative flex justify-center w-full h-80 min-h-fit max-h-fit">
      {restaurant.ImageHeader ? (
        <img
          src={`https://dk.takeoutresource.net/uploads/images/1920x450/${restaurant.ImageHeader}`}
          alt="restaurant_header_image"
          className="absolute z-0 object-cover w-full h-full brightness-50"
        />
      ) : (
        <img
          src={`https://dk.takeoutresource.net/uploads/images/240x240/${restaurant.Image}`}
          alt="restaurant_header_image"
          className="absolute z-0 object-cover w-full h-full brightness-50"
        />
      )}
      <div className="z-10 flex flex-col items-center justify-center w-full h-full mx-auto -m-5 text-white max-w-7xl">
        <h1 className="z-20 mx-auto mb-1 text-3xl">{restaurant?.ChainName}</h1>
        <p className="z-20 text-xl">
          {restaurant?.Address}, {restaurant?.Zip} {restaurant?.City}
        </p>
        {restaurant?.RestaurantTimes?.map((time) => {
          return (
            <div className="z-20 flex flex-row items-center justify-center space-x-1 text-lg" key={time.Day}>
              {time.Day === dayjs().day() &&
                (!time.Day.length > 0 ? (
                  <>{renderStatus(time.OpenTimes)}</>
                ) : (
                  <>
                    <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                    <p>Closed today</p>
                  </>
                ))}
            </div>
          );
        })}
      </div>

      <div className="absolute z-20 flex flex-row flex-wrap w-full gap-2 px-4 text-sm bottom-12 max-w-7xl">
        {/* TODO: add correct value for pickup validation */}
        {restaurant?.PreparationTime && (
          <>
            <div className="flex flex-row px-4 py-1 space-x-2 bg-white rounded-md">
              <p className="uppercase">Pick-up in:</p>
              <p>{restaurant.PreparationTime} min</p>
            </div>
            <div className="my-1 text-gray-200 bg-gray-200 border-r" />
          </>
        )}
        {restaurant?.DeliveryRanges?.length > 0 && (
          <>
            <div className="flex flex-row px-4 py-1 space-x-2 bg-white rounded-md">
              <p className="uppercase">Delivery from:</p>
              <p>{restaurant?.DeliveryRanges[0]?.Time} min</p>
            </div>
            <div className="my-1 text-gray-200 bg-gray-200 border-r" />
            <div className="flex flex-row px-4 py-1 space-x-2 bg-white rounded-md">
              <p className="uppercase">Delivery fee from:</p>
              <p>{restaurant?.DeliveryRanges[0]?.Fee} DKK</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RestaurantHeader;
