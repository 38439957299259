import DynamicIcon from '../../../shared/DynamicIcon';

const QuantitySelectorElement = ({ quantity, add, subtract }) => {
  return (
    <div className="bg-primary opacity-85 flex justify-between w-1/3 items-center rounded-3xl px-5 shadow-lg text-white shadow-gray-400 hover:drop-shadow-lg">
      {quantity === 1 ? (
        <div className="cursor-not-allowed text-gray-200">
          <DynamicIcon icon="MinusCircleIcon" width="w-8" height="h-8" aria-hidden="true" />
        </div>
      ) : (
        <div className="hover:scale-110 transition-all duration-300 cursor-pointer" onClick={subtract}>
          <DynamicIcon icon="MinusCircleIcon" width="w-8" height="h-8" aria-hidden="true" />
        </div>
      )}
      <div className="text-xl">{quantity}</div>
      <div className="hover:scale-110 transition-all duration-300 cursor-pointer" onClick={add}>
        <DynamicIcon icon="PlusCircleIcon" width="w-8" height="h-8" aria-hidden="true" />
      </div>
    </div>
  );
};

export default QuantitySelectorElement;
