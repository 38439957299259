import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import useCart from '../../hooks/useCart';
import useRestaurant from '../../hooks/useRestaurant';
import AppButton from '../shared/AppButton';
import DynamicIcon from '../shared/DynamicIcon';
import PriceBubble from '../shared/PriceBubble';
import CheckoutSkeleton from '../Skeletons/CheckoutSkeleton';

export default function CartMobile({ open, close }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 bottom-0 flex max-w-full pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full">
                <Dialog.Panel className="w-screen h-screen pointer-events-auto ">
                  <div className="fixed bottom-0 flex flex-col w-full py-6 overflow-y-scroll bg-white shadow-xl h-3/5">
                    <div className="flex items-center ml-5 cursor-pointer hover:opacity-90" onClick={close}>
                      <div className="flex items-center self-end justify-center w-10 h-10 mx-0 transition-all duration-200 ease-in rounded-full cursor-pointer text-primary bg-gray-50 hover:bg-gray-100">
                        <DynamicIcon icon="XMarkIcon" width="w-8" height="h-8" aria-hidden="true" />
                      </div>
                      <span className="ml-2 text-dark text-md">Close</span>
                    </div>
                    <div className="px-4 mt-6 sm:px-6">
                      <CheckoutCardMobile />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const CheckoutCardMobile = () => {
  const { restaurant } = useRestaurant();
  const { getStoredCart, incrementItem, decrementItem, removeItemFromCart, loading } = useCart();

  const navigate = useNavigate();

  const goToCheckout = () => {
    navigate('/checkout');
    // else if (restaurant.closed) {
    //     showAlert("Midlertidigt utilgængelig!")
    // }
  };

  const increment = (item) => {
    console.log(item);
    incrementItem(item);
  };

  const decrement = (item) => {
    console.log(item);
    decrementItem(item);
  };

  const removeItem = (item) => {
    removeItemFromCart(item);
  };

  const cart = getStoredCart();

  return (
    <div className="bg-white rounded-2xl px-4 py-4 mt-2 space-y-2 flex flex-col items-center sticky top-2 h-fit max-h-[600px]">
      <div className="text-xl font-medium text-dark">Your Order</div>
      {!cart ? (
        <div className="w-full px-2 py-5 border-2 border-dotted border-dark">
          <p className="text-center text-gray-600">
            {restaurant.DeliveryMinLimit
              ? `Minimal order for delivery in this restaurant is ${restaurant.DeliveryMinLimit?.toFixed(2)} DKK`
              : 'Your cart is empty'}
          </p>
        </div>
      ) : (
        <>
          {loading ? (
            <CheckoutSkeleton />
          ) : (
            <>
              <PriceBubble price={cart?.ItemFinal} currency="kr." />
              <div className="flex flex-col items-center w-full overflow-y-scroll h-4/5 overscroll-contain">
                {cart?.OrderItems.map((item, index) => (
                  <div className="w-full p-3 mt-4 bg-gray-100 md:w-1/2 rounded-2xl" key={index}>
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex ">
                          <div className="w-1/12 text-black cursor-pointer self-baseline hover:opacity-95 ">
                            <DynamicIcon onClick={() => removeItem(item)} icon="XMarkIcon" width="w-5" height="h-5" />
                          </div>
                          <div className="text-sm w-8/12 mr-0.5">
                            {/* x{item.Quantity}  */}
                            {item.ProductName} {item.ProductVersionName && item.ProductVersionName}
                          </div>
                          <div className="flex items-center justify-center w-3/12 px-2 bg-white rounded-xl self-baseline">
                            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125">
                              <DynamicIcon onClick={() => decrement(item)} icon="MinusIcon" width="w-4" height="h-4" />
                            </div>
                            <div className="mx-1">{item.Quantity}</div>
                            <div className="transition-all duration-200 hover:cursor-pointer hover:scale-125">
                              <DynamicIcon onClick={() => increment(item)} icon="PlusIcon" width="w-4" height="h-4" />
                            </div>
                          </div>
                        </div>

                        <div className="ml-5 self-baseline">
                          {item.Extras.length > 0 &&
                            item.Extras.map((extra, index) => (
                              <div className="flex flex-col" key={index}>
                                <div className="text-xs text-gray-500">
                                  + {extra.Name} ({extra.Price.toFixed(2)} kr.)
                                </div>
                              </div>
                            ))}
                          <div className="mt-4 text-sm font-medium text-dark">{item.PriceWithExtras.toFixed(2)} kr.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {cart && (
        <div className="w-4/5 pt-4 md:w-1/2">
          <AppButton text="Checkout" rounded textColor="text-white" textSize="text-lg" width="w-full" onClick={goToCheckout} />
        </div>
      )}
    </div>
  );
};
