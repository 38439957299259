/* eslint-disable react/no-unescaped-entities */
import Heading from '../../components/shared/Heading';

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <div className="px-4 mx-auto my-10 max-w-7xl text-dark">
      <Heading text="Privacy Policy" bold />
      <p className="mt-5">
        An updated version of our Privacy Policy takes effect on 25 May 2018.
        <br />
        <br />
        When you use TAKEOUT services, you trust us with your personal information. This Privacy Policy is meant to help you understand what data we collect,
        why we collect it, what we do with it and your options. This is important and we hope you will take your time to read it carefully.
        <br />
        <br />
        TAKEOUT uses the information we collect from all of our websites and apps to provide, maintain protect our services as well as improve or develop new
        services and also protect our users.
      </p>

      <h1 className="mt-5 text-3xl font-bold">1. Information that we collect and how we use it</h1>

      <br />
      <h2 className="text-xl font-bold">Device information</h2>
      <br />
      <p className="">
        We collect device specific information such as your hardware model, operating system version, browser or unique device identifiers. We do this firsly to
        assist you with any technical support questions as some issues can be related to specific devises. We also collect this information for analytical
        purposes in order to optimize all of our platforms and to know our customer base.{' '}
      </p>
      <br />
      <h2 className="text-xl font-bold">Location information</h2>
      <br />
      <p className="">
        When you use TAKEOUT services, we may collect and process information about your actual location in both address format and coordinates. We use various
        technologies to determine location, including Google services, IP address, GPS and other sensors. You can find your saved addresses or previous used
        addresses in your profile, where it is always possible for you to edit, review or delete these. We also store location information for each delivery
        order so we first can deliver your order to the correct address and secondly analyse behavioral patterns from our customers in order to provide better
        and faster services in the future.
      </p>
      <br />
      <h2 className="text-xl font-bold">Personal information</h2>
      <br />
      <p className="">
        This is information that you provide to us which personally identifies you, such as your name, email address or billing information, or other data which
        can somehow be linked to such information by TAKEOUT. Typically you will find this information under your account details. When you create an account
        with us, we ask for personal information so we can identify you when you log in, you can place orders according to the terms & conditions and we can
        deliver your order in a satisfying manner. You can always update this information under your profile or contact TAKEOUT if you either need help with
        this or wish to have your account deleted.
      </p>
      <br />
      <h2 className="text-xl font-bold">Third party information</h2>
      <br />
      <p className="">
        TAKEOUT collects information from third parties such as customer behavioral patterns, device information, location information and others. We do this to
        improve customer experience and assist our customers with support. Users have the option to log in with Facebook Authentication where the platform
        complies with Facebook policies found on https://developers.facebook.com/policy. User have the option to log in with G+ where the platform complies with
        Google+ Platform Developer Policies found on https://developers.google.com/+/web/policies.
      </p>
      <br />
      <h2 className="text-xl font-bold">Transaction information</h2>
      <br />
      <p className="">
        We collect information about your orders (such as the food you add to your basket), including payment information (such as your credit card number),
        using our payment service provider's secure services. Payment activities are outsourced to our payment processors, meaning we do not store your credit
        card information in our systems. We also collect delivery information such as coordinates, address information, name on the delivery address and contact
        phone number(s) to fulfill each order. Your transaction information allows us to process your order and send you an precise and exact bill. It also
        helps TAKEOUT and our restaurants to contact you if necessary.
      </p>
      <h1 className="mt-5 text-3xl font-bold">2. Who we share personal data with</h1>
      <br />

      <p className="">
        The data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes related to the purposes
        indicated. In addition to TAKEOUT, in some cases, the data may be accessible to certain types of persons in charge, involved with the operation of the
        site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail
        carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as data processors by TAKEOUT. The updated list of these
        parties may be requested from TAKEOUT at any time. To provide our services to you we may share your data with following parties: A) The restaurants you
        place an order at so they can process the order and deliver the order to you. B) Companies within Takeout Group A/S, meaning our subsidiaries and
        affiliated companies, our ultimate holding company and its subsidiaries and affiliated companies. These companies act on our behalf and process your
        personal information for the purposes stated in this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
