import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import axiosPublic from '../api/axiosPublic';
import RestaurantSearch from '../components/Index/RestaurantSearch';
import RestaurantSearchCard from '../components/Index/RestaurantSearchCard';
import AppButton from '../components/shared/AppButton';
import Heading from '../components/shared/Heading';
import Loader from '../components/shared/Loader';
import useCart from '../hooks/useCart';
import useOrder from '../hooks/useOrder';
import useRestaurant from '../hooks/useRestaurant';

const Index = () => {
  const [restaurantData, setRestaurantData] = useState([]);

  const { setCurrentRestaurant, company } = useRestaurant();
  const { clearStoredCart, cart } = useCart();
  const { mode } = useOrder();

  const navigate = useNavigate();

  const getRestaurants = async () => {
    const response = await axiosPublic('GetRestaurants', company?.ID, null);
    // console.log('RESTAURANTS', response.data?.Data.Restaurants);
    return response;
  };

  const { isLoading, error, status, data, isFetching, refetch } = useQuery({ queryKey: ['getRestaurants'], queryFn: getRestaurants, enabled: false });

  useEffect(() => {
    if (status === 'success') {
      setRestaurantData(data);
    }
  }, [status, data]);

  useEffect(() => {
    if (cart !== null) {
      clearStoredCart();
    }
  }, []);

  const handleSelect = (restaurant) => {
    setCurrentRestaurant(restaurant.ID);
    navigate(`/restaurant/${restaurant.Name}`);
  };

  return (
    <div className="w-full px-4 mt-40 h-96 min-h-fit">
      {mode === '' && <SelectModeSection />}
      {(mode === 'Delivery' || mode === 'Pickup') && (
        <RestaurantSearchSection isFetching={isFetching} refetch={refetch} data={restaurantData} setRestaurantData={setRestaurantData} onClick={handleSelect} />
      )}
    </div>
  );
};

export default Index;

const SelectModeSection = () => {
  const { setMode } = useOrder();
  const { company } = useRestaurant();

  return (
    <div className="flex flex-col items-center justify-center ">
      <h1 className="text-3xl text-white">Welcome to {company?.Name}</h1>
      <div className="mt-6">
        <Heading textColor="text-white" text="Choose your ordering method:" />
      </div>
      <div className="flex flex-col items-center justify-center w-full mt-3 space-y-3 md:space-y-0 md:space-x-5 md:flex-row">
        <div className="w-1/2 md:w-1/6">
          <AppButton onClick={() => setMode('Delivery')} text="Delivery" rounded width="w-full" />
        </div>
        <div className="w-1/2 md:w-1/6">
          <AppButton onClick={() => setMode('Pickup')} text="Pickup" rounded width="w-full" />
        </div>
      </div>
    </div>
  );
};

const RestaurantSearchSection = ({ isFetching, refetch, data, onClick, setRestaurantData }) => {
  const { mode, setMode } = useOrder();
  const { company } = useRestaurant();

  useEffect(() => {
    if (mode === 'Pickup' && company) {
      refetch();
    } else {
      setRestaurantData([]);
    }
  }, [mode]);

  return (
    <div className="flex flex-col items-center pb-10 space-y-5">
      <AppButton onClick={() => setMode('')} text="Go Back" rounded />
      {mode === 'Delivery' && <RestaurantSearch search={refetch} />}
      {isFetching ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        data?.data?.Data?.Restaurants.length > 0 && (
          <div className="flex flex-wrap justify-center w-full gap-2 px-2 mx-5 max-w-7xl h-[500px] md:h-full overflow-y-scroll">
            {data?.data?.Data?.Restaurants.map(
              (restaurant) => (
                // !restaurant.HiddenWhiteLabel && (
                <RestaurantSearchCard
                  key={restaurant.ID}
                  name={restaurant.Name}
                  address={restaurant.Address}
                  city={restaurant.City}
                  zip={restaurant.Zip}
                  onClick={() => onClick(restaurant)}
                />
              )
              // )
            )}
          </div>
        )
      )}
    </div>
  );
};
