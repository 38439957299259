import React, { useContext, useEffect, useRef, useState } from 'react';

import axiosPublic from '../../api/axiosPublic';
import useAuth from '../../hooks/useAuth';
import DynamicIcon from '../shared/DynamicIcon';
import InputElement from '../shared/FormElements/InputElement';

const LoginForm = ({ switchType, close }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { setStoredToken } = useAuth();

  const errRef = useRef();

  const clearFields = () => {
    setEmail('');
    setPassword('');
    setShowPassword(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = {
        Email: email,
        Password: password
      };
      console.log(data);
      const response = await axiosPublic('AuthenticateUser', '', data);
      console.log('response', response);
      clearFields();
      // setRestaurant(response.data?.Data);
      // setToken(response.data?.Data.Token);
      setStoredToken(response.data?.Data.Token);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="text-dark">
      <h1 className="text-3xl font-medium"> Welcome! </h1>
      <p className="text-xl mt-3">
        Log in or create an account.
        <br /> We make it easy to order your favorite takeaway! 🤩
      </p>
      {/* <div>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            value="Facebook"
            required
            className="rounded-3xl text-md w-full px-3 py-3 border-2 border-white bg-white placeholder-white text-secondary text-center focus:outline-none focus:ring-none"
            placeholder="Facebook"
          />
        </div>
        <div className="flex w-full items-center justify-center my-3">
          <hr className="w-1/3 mx-auto border-0.5 bg-white border-white" />
          <span>or</span>
          <hr className="w-1/3 mx-auto  bg-white border-white" />
        </div> */}
      <div className="mt-6">
        <form className="" action="#" method="POST" onSubmit={handleLogin}>
          <div className="flex flex-col space-y-2">
            <InputElement
              // labelText="Email Address*"
              required
              name="email"
              type="email"
              placeholder="Email address*"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <div className="mt-2 relative">
              <InputElement
                // labelText="Password*"
                required
                name="password"
                type={!showPassword ? 'password' : 'text'}
                placeholder="Password*"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-dark leading-5 cursor-pointer" onClick={toggleShowPassword}>
                {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end">
            <p className="text-md cursor-pointer mt-2 w-fit font-medium hover:opacity-80">Forgot password</p>
          </div>
          <div className="mt-5">
            <button
              type="submit"
              disabled={!email || !password}
              className={`${
                !email || !password ? 'text-white bg-gray-300' : 'text-white bg-primary'
              } group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-3xl hover:opacity-90 transition-all duration-300 focus:ring-none`}>
              <span className="absolute right-3 inset-y-0 flex items-center pl-3">
                <DynamicIcon icon="LockClosedIcon" />
              </span>
              Log in
            </button>
          </div>
        </form>
        <hr className="my-5 w-1/3 mx-auto border-0.5 bg-dark border-dark" />
        <h2 className="text-md font-medium text-dark">
          Don&apos;t have an account?{' '}
          <span onClick={switchType} className="font-bold hover:cursor-pointer hover:opacity-90">
            Register
          </span>{' '}
        </h2>
      </div>
    </div>
  );
};

export default LoginForm;
