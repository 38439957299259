import React from 'react';

const InputElement = ({ name, type, required, placeholder, value, onChange, labelText }) => {
  return (
    <div className="text-left">
      {labelText && (
        <label htmlFor={name} className="">
          {labelText}
        </label>
      )}
      <input
        id={name}
        name={name}
        type={type ? type : 'text'}
        autoComplete={name}
        onChange={onChange}
        value={value}
        required={required}
        placeholder={placeholder && placeholder}
        className="mt-0.5 rounded-xl bg-transparent w-full px-3 py-3 border-2 border-dark placeholder-gray-300 text-dark text-left focus:outline-none focus:ring-primary focus:border-primary focus:z-10 text-md"
      />
    </div>
  );
};

InputElement.defaultProps = {
  name: 'Name',
  type: 'text',
  autoComplete: 'bg-primary',
  required: false,
  placeholder: 'Placeholder'
};

export default InputElement;
