import { useEffect, useState } from 'react';

import { useOutletContext } from 'react-router-dom';

import axiosPublic from '../../api/axiosPublic';
import RestaurantDetails from '../../components/Restaurant/Info/RestaurantDetails';
import RestaurantTimesCard from '../../components/Restaurant/Info/RestaurantTimesCard';

const RestaurantInfo = () => {
  const { restaurant } = useOutletContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const getMenuCard = async () => {
    setLoading(true);

    try {
      const response = await axiosPublic('GetMenucard', { RestaurantID: 1713 });
      console.log(response.data?.Data);
      // setMenu(response.data?.Data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col md:flex-row h-full mb-2 w-full">
      <div className="w-full md:w-1/3 lg:w-1/4">
        <RestaurantTimesCard restaurant={restaurant} />
      </div>
      <div className="hidden md:block border border-white bg-white mx-4 my-2 mb-10" />
      <div className="w-full md:w-2/3 lg:w-3/4 flex flex-col">
        <RestaurantDetails restaurant={restaurant} />
      </div>
      <div className="hidden lg:block border border-white bg-white mx-4 my-2 mb-10" />
    </div>
  );
};

export default RestaurantInfo;
