import { useContext } from 'react';

import { Outlet } from 'react-router-dom';

// import Footer from '../components/shared/Footer';
import Navbar from '../components/shared/Navbar';
import RestaurantContext from '../contexts/RestaurantContext';

const Root = () => {
  const { company } = useContext(RestaurantContext);

  return (
    <div className="h-screen min-h-fit">
      <div id="navbar">
        <Navbar />
      </div>
      <div className="relative w-full h-full">
        {company?.ImageBackground ? (
          <img
            src={`https://dk.takeoutresource.net/uploads/images/240x240/${company?.ImageBackground}`}
            alt={`${company?.Name} background`}
            className="absolute object-cover w-full h-full brightness-50 -z-10 blur-sm"
          />
        ) : (
          <div className="absolute w-full h-full bg-secondary -z-10" />
        )}
        <div className="z-10 flex items-center w-full mx-auto max-w-7xl">
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Root;
