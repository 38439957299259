const PriceBubbleElement = ({ onClick, price }) => {
  return (
    <div
      onClick={onClick}
      className="w-2/3 flex bg-secondary text-white items-center rounded-3xl hover:opacity-90 cursor-pointer transition-all duration-300 shadow-lg shadow-gray-400  hover:drop-shadow-lg">
      <div className="w-1/2 font-medium">Add to cart</div>
      <div className="w-1/2">{price} kr.</div>
    </div>
  );
};

export default PriceBubbleElement;
