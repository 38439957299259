import { useOutletContext } from 'react-router-dom';

import convertTime from '../../../helpers/convertTime';
import Heading from '../../shared/Heading';

const RestaurantTimesCard = () => {
  const { restaurant } = useOutletContext();
  const days = [
    { day: 'Monday', index: '1' },
    { day: 'Tuesday', index: '2' },
    { day: 'Wednesday', index: '3' },
    { day: 'Thursday', index: '4' },
    { day: 'Friday', index: '5' },
    { day: 'Saturday', index: '6' },
    { day: 'Sunday', index: '0' }
  ];

  return (
    <>
      {restaurant?.RestaurantTimes && (
        <div>
          <div className="sticky top-2 mb-10">
            <Heading text="Opening hours" />
            <div className="bg-white rounded-2xl px-2 py-4 mt-2 space-y-2">
              {days.map((day) => {
                return (
                  <div key={day.index} className="text-dark mx-2 mb-1 flex flex-row justify-between text-sm">
                    <div className="text-dark">{day.day}</div>
                    <div>
                      {restaurant?.RestaurantTimes[day.index]?.OpenTimes === '' ? 'Closed' : convertTime(restaurant?.RestaurantTimes[day.index]?.OpenTimes)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sticky top-2 mb-10">
            <Heading text="Delivery hours" />
            <div className="bg-white rounded-2xl px-2 py-4 mt-2 space-y-2">
              {days.map((day) => {
                return (
                  <div key={day.index} className="text-dark text-sm mx-2 mb-1 flex flex-row justify-between">
                    <div className="text-dark">{day.day}</div>
                    <div>
                      {restaurant?.RestaurantTimes[day.index]?.DeliveryTimes === ''
                        ? 'Closed'
                        : convertTime(restaurant?.RestaurantTimes[day.index]?.DeliveryTimes)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RestaurantTimesCard;
