import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import axiosPublic from '../../../../api/axiosPublic';
import useAddress from '../../../../hooks/useAddress';
import useOrder from '../../../../hooks/useOrder';
import useRestaurant from '../../../../hooks/useRestaurant';
import RadioElement from '../../../shared/FormElements/RadioElement';
import Loader from '../../../shared/Loader';

const TimeSection = ({ mode }) => {
  // const [selectedTimeOption, setSelectedTimeOption] = useState('As soon as possible');
  const [loading, setLoading] = useState(false);
  const [timeOptions, setTimeOptions] = useState(null);

  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState(null);

  const [dateSelectors, setDateSelectors] = useState([]);
  const [timeSelectors, setTimeSelectors] = useState([]);

  const [timesForSelectedDay, setTimesForSelectedDay] = useState([]);

  const { selectedTimeOption, setSelectedTimeOption, selectedAddress } = useOrder();
  const { currentAddress, getStoredAddress } = useAddress();
  const { restaurantId } = useRestaurant();
  const current_address = getStoredAddress();

  useEffect(() => {
    if ((mode === 'Delivery' && current_address?.length > 0) || mode === 'Pickup') {
      getTimes();
    }
  }, [currentAddress, mode, selectedAddress]);

  const getTimes = async () => {
    setLoading(true);
    const _address = JSON.parse(current_address);
    const data = {
      RestaurantID: restaurantId,
      Latitude: _address?.latitude,
      Longitude: _address?.longitude,
      TakeoutType: mode === 'Delivery' ? 2 : 1
      // TakeoutType: 1
    };
    // console.log(data);

    try {
      const response = await axiosPublic('GetOrderTimes', '', data);
      console.log(response.data);
      if (response.data.Data) {
        setTimeOptions(response.data.Data);
        setDateSelectors(response.data.Data?.DateSelectors);
        setTimeSelectors(response.data.Data?.TimeSelectors);

        setSelectedDay(response.data.Data?.DateSelectors[0]);
        setTimesForSelectedDay(response.data.Data?.TimeSelectors[0]);
      } else {
        toast.warn('Cannot get times for this address...');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.warn('Cannot get Times. Please try again later...');
      setLoading(false);
    }
  };

  function handleDayChange(event) {
    const selectedDay = event.target.value;
    setSelectedDay(selectedDay);

    // find the index of the selected day in the DateSelectors array
    const dayIndex = dateSelectors.indexOf(selectedDay);

    // get the array of times for the selected day
    const timesForSelectedDay = timeSelectors[dayIndex];
    setTimesForSelectedDay(timesForSelectedDay);
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        timeOptions && (
          <div className="flex flex-col mt-4 space-y-2">
            <div className="flex flex-col">
              <RadioElement
                labelText="As soon as possible"
                value="As soon as possible"
                checked={selectedTimeOption === 'As soon as possible'}
                onChange={(e) => setSelectedTimeOption(e.target.value)}
                id="asap"
                name="asap"
                big
              />
              <div className="ml-8 text-sm text-gray-500">Today at: {dayjs(timeOptions?.EarliestDateTime).format('HH:mm')}</div>
            </div>
            <div className="flex flex-col">
              <RadioElement
                labelText="Later"
                value="later"
                checked={selectedTimeOption === 'later'}
                onChange={(e) => setSelectedTimeOption(e.target.value)}
                id="later"
                name="later"
                big
              />
              {selectedTimeOption === 'later' && (
                <div className="grid w-full grid-cols-12 mt-1 text-left gap-x-2">
                  <select
                    name="date"
                    id="date"
                    autoComplete="date"
                    onChange={handleDayChange}
                    value={selectedDay}
                    className="block w-full col-span-5 px-3 py-2 mt-1 bg-white border-2 rounded-md shadow-sm cursor-pointer border-dark focus:outline-none focus:ring-primary focus:border-primary sm:text-sm">
                    {/* <option value={''}>Select Address</option> */}
                    {dateSelectors &&
                      dateSelectors.map((date, index) => (
                        <option value={date} key={index}>
                          {dayjs(date).format('dddd, MMMM D')}
                        </option>
                      ))}
                  </select>
                  <select
                    name="time"
                    id="time"
                    autoComplete="time"
                    onChange={(e) => setSelectedTime(e.target.value)}
                    value={selectedTime}
                    className="block w-full col-span-2 px-3 py-2 mt-1 bg-white border-2 rounded-md shadow-sm cursor-pointer border-dark focus:outline-none focus:ring-primary focus:border-primary sm:text-sm">
                    {/* <option value={''}>Select Address</option> */}
                    {timesForSelectedDay &&
                      timesForSelectedDay.map((time, index) => (
                        <option value={time} key={index}>
                          {dayjs(time).format('HH:mm')}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TimeSection;
