import axios from 'axios';

const axiosPublic = async (func, company, data) => {
  const json = {
    Website: 'Denmark',
    ClientCode: process.env.REACT_APP_TAKEOUT_CLIENT_CODE,
    CompanyID: company ? company : '',
    ClientVersion: 1.3,
    Language: 'en-US',
    TestMode: true,
    // TestMode: false,
    Function: func,
    Data: data
  };

  return await axios({
    method: 'POST',
    url: process.env.REACT_APP_TAKEOUT_URL,
    data: `jsonrequest=${JSON.stringify(json)}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
};

export default axiosPublic;
