import * as HIcons from '@heroicons/react/24/outline';

const DynamicIcon = ({ icon, height, width, color, hover, onClick }) => {
  const { ...icons } = HIcons;
  const TheIcon = icons[icon];

  return (
    <>
      {/* <TheIcon className={`h-${height} w-${width} text-${color}-500, {${hover} ? 'hover:text-${hover}-500' : ''}`} aria-hidden="true" /> */}
      <TheIcon
        onClick={onClick && onClick}
        className={`${height} ${width} ${color}, {${hover} ? ${hover} : ''}`}
        aria-hidden="true"
        data-testid="dynamic-icon"
      />
    </>
  );
};

DynamicIcon.defaultProps = {
  color: 'text-black',
  height: 'h-6',
  width: 'w-6',
  icon: 'ComputerDesktopIcon'
};

export default DynamicIcon;
