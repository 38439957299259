import DynamicIcon from '../../shared/DynamicIcon';
import Heading from '../../shared/Heading';

const RestaurantDetails = ({ restaurant }) => {
  return (
    <div className="flex flex-col space-y-10">
      {restaurant?.Description && (
        <div className="flex flex-col">
          <Heading text="Restaurant" />
          <p className="text-md text-gray-700 mt-1">{restaurant?.Description}</p>
        </div>
      )}
      <div className="flex flex-col space-y-1">
        <Heading text="See on the map" />
        <div className="h-60">
          <iframe
            style={{ borderRadius: 10 }}
            title="addressMap"
            width="100%"
            height="100%"
            id="gmap_canvas"
            src={`https://maps.google.com/maps?q=${restaurant.City},${restaurant.Address}&t=&z=14&ie=UTF8&iwloc=&output=embed`}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"></iframe>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <DynamicIcon icon="PhoneIcon" width="w-8" height="h-8" />
        <p className="text-dark text-lg font-medium">{restaurant?.Phone}</p>
      </div>
    </div>
  );
};

export default RestaurantDetails;
