import React from 'react';

const Heading = ({ uppercase, text, small, big, bold, textColor }) => {
  return (
    <h1
      className={`${uppercase && 'uppercase'} ${small ? 'text-md' : big ? 'text-3xl' : 'text-2xl'} ${textColor ? textColor : 'text-dark'} ${
        bold ? 'font-bold' : 'font-medium'
      }`}>
      {text}
    </h1>
  );
};

export default Heading;
