import { useState } from 'react';

import DynamicIcon from '../../../shared/DynamicIcon';

const DiscountSection = ({ onClick }) => {
  const [discountCode, setDiscountCode] = useState('');

  return (
    <div className="flex flex-row w-2/5 mt-4 bg-white rounded-xl">
      <input
        type="text"
        onChange={(e) => {
          setDiscountCode(e.target.value);
        }}
        value={discountCode}
        required
        className="w-4/5 px-3 py-3 text-left placeholder-gray-500 border-2 border-r-0 border-dark rounded-l-xl text-dark focus:ring-0 focus:ring-primary focus:border-primary focus:z-10 text-md"
        placeholder="Discount code"
      />
      <div
        className={`${
          !discountCode.length ? 'bg-gray-400' : 'bg-primary cursor-pointer hover:opacity-90'
        } text-white rounded-r-xl w-2/5 flex items-center justify-center`}
        onClick={onClick}>
        <DynamicIcon icon="ChevronRightIcon" />
      </div>
    </div>
  );
};

export default DiscountSection;
