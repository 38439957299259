import { Outlet, useNavigate } from 'react-router-dom';

import DynamicIcon from '../../components/shared/DynamicIcon';
import UserNavigation from '../../components/User/UserNavigation';
import useAuth from '../../hooks/useAuth';

const User = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full px-4 mx-auto mt-10 max-w-7xl">
      {/* <h1 className="mb-4 text-3xl font-bold text-dark">User Profile</h1> */}
      <div>
        <div className="flex items-center cursor-pointer w-fit hover:opacity-90" onClick={() => navigate('/restaurant')}>
          <div className="flex items-center self-end justify-center w-10 h-10 p-1 transition-all duration-200 ease-in bg-gray-100 rounded-full cursor-pointer sm:mx-0 text-primary">
            <DynamicIcon icon="ChevronLeftIcon" width="w-8" height="h-8" aria-hidden="true" />
          </div>
          <span className="w-full ml-2 font-medium text-dark text-md">Back to restaurant</span>
        </div>
      </div>
      <div className="flex flex-col mt-5 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
        <UserNavigation />
        <div className="w-full h-full p-5 mx-auto bg-white shadow-md min-h-fit rounded-3xl lg:w-4/5 ">
          <Outlet context={{ user }} />
        </div>
      </div>
    </div>
  );
};

export default User;
