import React, { createContext, useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import axiosPublic from '../api/axiosPublic';
import useRestaurant from '../hooks/useRestaurant';

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
  const CART_LOCALSTORAGE_KEY = 'cart';
  const CART_ITEMS_LOCALSTORAGE_KEY = 'cart_items';

  const [cart, setCart] = useState(localStorage.getItem(CART_LOCALSTORAGE_KEY) || null);
  const [cartItems, setCartItems] = useState(localStorage.getItem(CART_ITEMS_LOCALSTORAGE_KEY) || []);
  const [loading, setLoading] = useState(false);

  const { restaurantId } = useRestaurant();

  const setStoredCartItems = (cartItems) => {
    localStorage.setItem(CART_ITEMS_LOCALSTORAGE_KEY, cartItems);
    setCartItems(cartItems);
  };

  const setStoredCart = (data) => {
    localStorage.setItem(CART_LOCALSTORAGE_KEY, data);
    setCart(data);
  };

  const clearStoredCart = () => {
    localStorage.removeItem(CART_LOCALSTORAGE_KEY);
    setCart(null);
    setCartItems([]);
  };

  const getStoredCart = () => {
    if (cart !== null) {
      return JSON.parse(cart);
    }
    return null;
  };

  const getStoredItems = () => {
    let storedItems;
    if (cartItems?.length) {
      storedItems = JSON.parse(cartItems);
      // console.log('CART ITEMS', storedItems.OrderItems);
      return storedItems;
    }
    return null;
  };

  const clearState = () => {
    setCart(null);
    setCartItems([]);
    localStorage.removeItem(CART_LOCALSTORAGE_KEY);
    localStorage.removeItem(CART_ITEMS_LOCALSTORAGE_KEY);
  };

  const assembleItems = (item, quantity) => {
    const items = [];
    // First, add quantity, selected product ID and version ID to orderItem obj
    let orderItem = {
      Group: '',
      ProductID: item.ProductID,
      ProductVersionID: item.ProductVersionID,
      Quantity: quantity
    };

    // Then create a new array of ExtraList that holds ID's of each item in extraList
    const ExtraList = item.Extras.map((x) => x.ID);

    // Then, add selected extras to orderItem obj
    orderItem.ExtraList = ExtraList;

    // Finally, push orderItem obj to items array
    items.push(orderItem);
    // console.log(items);

    return items;
  };

  const performCartAction = async (cart) => {
    setLoading(true);
    try {
      const response = await axiosPublic('VerifyOrderItems', '', cart);
      console.log('VERIFIED', response);

      const cartSave = JSON.stringify(response.data?.Data);

      if (!response.data?.Data?.OrderItems.length) {
        clearState();
      } else {
        setStoredCartItems(response.data?.Data?.OrderItemsJson);
        setStoredCart(cartSave);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Something went wrong. Please try again.');
    }
  };

  const incrementItem = async (item) => {
    const storedItems = getStoredItems();

    const newItems = assembleItems(item, 1);

    const cart = {
      RestaurantID: restaurantId,
      OrderItems: storedItems?.OrderItems,
      Add: newItems
    };

    performCartAction(cart);
  };

  const decrementItem = async (item) => {
    const storedItems = getStoredItems();

    const itemsToRemove = assembleItems(item, 1);

    const cart = {
      RestaurantID: restaurantId,
      OrderItems: storedItems?.OrderItems,
      Remove: itemsToRemove
    };

    performCartAction(cart);
  };

  const removeItemFromCart = async (item) => {
    const storedItems = getStoredItems();

    const itemsToRemove = assembleItems(item, 0);

    const cart = {
      RestaurantID: restaurantId,
      OrderItems: storedItems?.OrderItems,
      Remove: itemsToRemove
    };

    performCartAction(cart);
  };

  const cartProviderValue = {
    setCart,
    getStoredCart,
    setStoredCartItems,
    setStoredCart,
    incrementItem,
    decrementItem,
    removeItemFromCart,
    setLoading,
    clearStoredCart,
    cart,
    loading,
    cartItems
  };

  return <CartContext.Provider value={cartProviderValue}>{children}</CartContext.Provider>;
};

export default CartContext;
