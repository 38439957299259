import { createContext, useState } from 'react';

import { useGetUser } from '../hooks/useUserQuery';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const USER_LOCALSTORAGE_KEY = 'user_token';
  const localStorage = window.localStorage;

  const [token, setToken] = useState(localStorage.getItem(USER_LOCALSTORAGE_KEY) || '');
  const [user, setUser] = useState(null);

  const onSuccess = (data) => {
    console.log(data);
    setUser(data.data?.Data);
  };

  const onError = (error) => {
    console.log(error);
    // TODO: throw error toast and get back to home page
  };

  const { data } = useGetUser(onSuccess, onError, token);

  const setStoredToken = (token) => {
    localStorage.setItem(USER_LOCALSTORAGE_KEY, token);
    setToken(token);
  };

  const clearStoredToken = () => {
    localStorage.removeItem(USER_LOCALSTORAGE_KEY);
    setToken('');
  };

  return <AuthContext.Provider value={{ token, setToken, user, setUser, setStoredToken, clearStoredToken }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
