import React from 'react';

const PriceBubble = ({ price, currency, small }) => {
  return (
    <div className={`bg-gray-100 ${small ? 'px-4' : 'px-8'} py-1 rounded-3xl w-max`}>
      <div className={`text-primary ${small ? 'text-md' : 'text-lg'}`}>
        {Number(price).toFixed(2)} {currency}
      </div>
    </div>
  );
};

export default PriceBubble;
