import React from 'react';

import { NavLink } from 'react-router-dom';

const UserNavigation = () => {
  const pages = [
    { name: 'Profile info', path: `/user/profile-info`, end: true },
    { name: 'Recent orders', path: `/user/recent-orders` }
  ];

  const activeLink = 'text-primary font-medium border-b-2 border-primary -mb-0.5 w-max';

  return (
    <div className="bg-white p-5 rounded-3xl w-full lg:w-1/5 shadow-md h-max">
      <div className="w-full flex flex-col mx-auto h-full space-y-5">
        {pages.map((page) => (
          <NavLink
            key={page.name}
            end={page.end}
            to={page.path}
            className={({ isActive }) => (isActive ? activeLink : 'text-gray border-2 border-transparent hover:opacity-80 outline-none -mb-0.5')}>
            <p className="text-2xl hover:text-primary"> {page.name} </p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default UserNavigation;
