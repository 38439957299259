import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Auth from './layouts/Auth';
import Main from './layouts/Main';
import Root from './layouts/Root';
import Checkout from './pages/Checkout';
import ErrorPage from './pages/ErrorPage';
import PrivacyPolicy from './pages/Footer/PrivacyPolicy';
import TermsAndConditions from './pages/Footer/TermsAndConditions';
import Index from './pages/Index';
import Receipt from './pages/Receipt';
import Restaurant from './pages/Restaurant/index';
import RestaurantInfo from './pages/Restaurant/RestaurantInfo';
import RestaurantMenu from './pages/Restaurant/RestaurantMenu';
import User from './pages/User';
import UserProfileInfo from './pages/User/UserPersonalInfo';
import UserRecentOrders from './pages/User/UserRecentOrders';

function App() {
  return (
    <>
      <ToastContainer autoClose={3000} position="top-right" newestOnTop />
      <Routes>
        <Route element={<Root />}>
          <Route path="/order" element={<Index />} />
          <Route path="/" element={<Navigate to="/order" />} />
        </Route>
        <Route element={<Main />}>
          {/* <Route index element={<Index />} /> */}
          <Route path="restaurant" element={<Restaurant />}>
            <Route path="/restaurant" element={<Navigate to="restaurant" />} />
            <Route index path=":name" element={<RestaurantMenu />} />
            <Route path=":name/info" element={<RestaurantInfo />} />
          </Route>
          <Route path="checkout" element={<Checkout />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route element={<Auth />}>
            <Route path="user" element={<User />}>
              <Route path="/user" element={<Navigate to="/user/profile-info" />} />
              <Route path="profile-info" element={<UserProfileInfo />} />
              <Route path="recent-orders" element={<UserRecentOrders />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
