/* eslint-disable react/no-unescaped-entities */
import Heading from '../../components/shared/Heading';

const TermsAndConditions = () => {
  window.scrollTo(0, 0);

  return (
    <div className="px-4 mx-auto my-10 max-w-7xl text-dark">
      <Heading text="Terms & Conditions" bold />
      <p className="mt-5">
        We have made revisions to our Terms & Conditions, effective May 25th, 2018.
        <br />
        <br />
        Any customer using https://takeout.dk/, https://menukitt.dk/, https://menukitt.com/, https://bestilonline.dk, https://bakerlane.dk/, http://fitmeal.dk/
        or any subdomains directly or indirectly controls, is controlled by, or is under common control with TAKEOUT ApS, TAKE-OUT ApS, TAKEAWAYS ApS,
        TAKEOUT.DK ApS, TAKEOUT GROUP A/S, DRIVERKITT A/S, E-TAKEAWAY HOLDING A/S, MENUKITT A/S, TAKEOUT HOLDING A/S or TAKEOUTGROUP.COM A/S (From hereon
        ''TAKEOUT'') is agreeing to these terms.
      </p>

      <h1 className="mt-5 text-3xl font-bold">1. Who may use the services</h1>
      <br />
      <p className="">
        You may use the Services only if you have created a user and thereby agree to form a binding contract with TAKEOUT. If you are accepting these Terms &
        Conditions and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are
        authorized to do so.
      </p>
      <h1 className="mt-5 text-3xl font-bold">2. Privacy Policy</h1>
      <br />
      <p className="">
        Our Privacy Policy (https://takeout.dk/privacy) describes how we handle the information you provide to us when you use our services. You understand that
        through your use of the services you consent to the collection and use (as set forth in the Privacy Policy) of this information, including the transfer
        of this information to the United States, Lithuania, and/or other countries for storage, processing and use by TAKEOUT as well as any sub data
        processors working in collaboration of TAKEOUT.
      </p>
      <h1 className="mt-5 text-3xl font-bold">3. Ending your account</h1>
      <br />
      <p className="">
        You will always have access to your account where you can access, review or update any of your personal information. If you wish to end your account,
        agreement and contract with TAKEOUT at any time by deactivating your account and discontinuing your use of the Services, we ask that you contact us via
        the details found on our website.
      </p>
      <h1 className="mt-5 text-3xl font-bold">4. Orderingprocess and delivery</h1>
      <br />
      <p className="">
        When you have selected the appropriate products and wish to complete your order from the chosen restaurant. Submit your order by clicking "place order",
        please make sure the information given is correct. As soon as the order have been submitted, the restaurant are working on your order and changes will
        not be possible.
      </p>
      <h1 className="mt-5 text-3xl font-bold">5. Customer service</h1>
      <br />
      <p className="">
        Customer service is very important for menukitt. Our customer service team will, to the best of their ability try and help any problems that might occur
        with your order. It is possible to contact customer service, by clicking "contact us by email", "FAQ" or calling our phonenumber on the frontpage.
      </p>
    </div>
  );
};

export default TermsAndConditions;
