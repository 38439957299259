const convertTime = (time) => {
  const timeArray = time.split(',');
  const formattedTimeArray = timeArray.map((time) => time.padStart(2, '0'));
  const formattedTimeString = formattedTimeArray.join(':');

  const timeArray2 = formattedTimeString.split(':');
  const startTimeArray = timeArray2.slice(0, 2);
  const startTimeString = startTimeArray.join(':');
  const endTimeArray = timeArray2.slice(2);
  const endTimeString = endTimeArray.join(':');
  const result = startTimeString + ' - ' + endTimeString;
  return result;
};

export default convertTime;
