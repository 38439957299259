import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import axiosPublic from '../../api/axiosPublic';
import useAuth from '../../hooks/useAuth';
import AppButton from '../shared/AppButton';
import DynamicIcon from '../shared/DynamicIcon';
import InputElement from '../shared/FormElements/InputElement';

const RegisterForm = ({ switchType, close }) => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [marketingMaterials, setMarketingMaterials] = useState(false);
  const [orderInformation, setOrderInformation] = useState(false);

  const { setStoredToken } = useAuth();

  const clearFields = () => {
    setFullName('');
    setPhoneNumber('');
    setEmail('');
    setPassword('');
    setShowPassword(false);
    setTermsAndConditions(false);
    setPrivacyPolicy(false);
    setMarketingMaterials(false);
    setOrderInformation(false);
  };

  const handleRegister = async () => {
    try {
      const data = {
        Email: email,
        Password: password,
        Name: fullName,
        Phone: phoneNumber,
        PushNotifications: orderInformation,
        Newsletter: marketingMaterials,
        Type: 1
      };
      console.log(data);
      const response = await axiosPublic('CreateUser', '', data);
      console.log('response', response.data.Data);
      clearFields();
      setStoredToken(response.data?.Data.Token);
      // setRestaurant(response.data?.Data);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleTermsAndConditions = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const togglePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy);
  };

  const toggleMarketingMaterials = () => {
    setMarketingMaterials(!marketingMaterials);
  };

  const toggleOrderInformation = () => {
    setOrderInformation(!orderInformation);
  };

  return (
    <div className="text-dark">
      <h1 className="text-3xl font-medium"> Create account </h1>
      <div className="mt-6">
        <div className="my-2">
          <form className="w-96 min-w-max" action="#" method="POST" onSubmit={handleRegister}>
            <div className="flex flex-col space-y-2">
              <InputElement required name="fullName" placeholder="Full name*" onChange={(e) => setFullName(e.target.value)} value={fullName} />
              <InputElement
                required
                name="phoneNumber"
                type="number"
                placeholder="Phone Number*"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
              <InputElement required name="email" type="email" placeholder="Email address*" onChange={(e) => setEmail(e.target.value)} value={email} />
              <div className="mt-2 relative">
                <InputElement
                  required
                  name="password"
                  type={!showPassword ? 'password' : 'text'}
                  placeholder="Password*"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-dark leading-5 cursor-pointer" onClick={toggleShowPassword}>
                  {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
                </div>
              </div>
            </div>
            <div className="my-4 ml-4 flex flex-col space-y-2">
              <div className="flex items-center">
                <input
                  id="marketingMaterials"
                  name="Newsletter"
                  type="checkbox"
                  onChange={toggleMarketingMaterials}
                  checked={marketingMaterials}
                  className="h-5 w-5 rounded-md border-2 border-dark bg-transparent text-primary focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="marketingMaterials" className="ml-2 block text-sm text-dark hover:cursor-pointer">
                  I would like to receive marketing materials
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="orderInformation"
                  name="Order Information"
                  type="checkbox"
                  onChange={toggleOrderInformation}
                  checked={orderInformation}
                  className="h-5 w-5 rounded-md border-2 border-dark bg-transparent text-primary focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="orderInformation" className="ml-2 block text-sm text-dark hover:cursor-pointer">
                  I would like to receive order information
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="termsAndConditions"
                  name="Terms and Conditions"
                  type="checkbox"
                  onChange={toggleTermsAndConditions}
                  checked={termsAndConditions}
                  className="h-5 w-5 rounded-md border-2 border-dark bg-transparent text-primary focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="termsAndConditions" className="ml-2 block text-sm text-dark hover:cursor-pointer">
                  I have read and accepted{' '}
                  <Link className="underline" to="/terms-and-conditions" target="_blank">
                    {' '}
                    Terms & Conditions{' '}
                  </Link>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="privacyPolicy"
                  name="Privacy Policy"
                  type="checkbox"
                  onChange={togglePrivacyPolicy}
                  checked={privacyPolicy}
                  className="h-5 w-5 rounded-md border-2 border-dark bg-transparent text-primary focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="privacyPolicy" className="ml-2 block text-sm text-dark hover:cursor-pointer">
                  I have read and accepted{' '}
                  <Link className="underline" to="/privacy-policy" target="_blank">
                    {' '}
                    Privacy Policy{' '}
                  </Link>
                </label>
              </div>
            </div>
            <div className="mt-3">
              <AppButton
                type="submit"
                text="Submit"
                disabled={!termsAndConditions || !privacyPolicy || !fullName.length || !phoneNumber.length || !email.length || !password.length}
                // disabled={!termsAndConditions || !privacyPolicy}
                rounded
                width="w-full"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
