import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import noImage from '../../assets/images/no_image.png';
import RestaurantContext from '../../contexts/RestaurantContext';

const Footer = () => {
  const { company } = useContext(RestaurantContext);

  const footerLinks = [
    { name: 'Terms & Conditions', path: '/terms-and-conditions' },
    { name: 'Privacy policy', path: '/privacy-policy' }
  ];

  return (
    <div className="w-full px-5 py-5 mx-auto bg-white border-t-2 border-gray-100">
      <div className="flex justify-between mx-auto max-w-7xl">
        <div className="flex items-center space-x-5">
          {company?.Image ? (
            <img
              src={`https://dk.takeoutresource.net/uploads/images/240x240/${company?.Image}`}
              alt={`${company?.Name} logo`}
              className="object-cover w-12 h-12"
            />
          ) : (
            <img src={noImage} alt="menu_item" className="object-contain w-full h-12" />
          )}
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-bold text-dark">Restaurant</h1>
            <p className="text-gray-500">© 2022 Takeout Group A/S All rights reserved</p>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <ul className="flex justify-end space-x-5">
            {footerLinks.map((link) => (
              <li key={link.name} className="text-md hover:opacity-80 hover:text-primary">
                <Link to={link.path}>{link.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
