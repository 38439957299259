import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const Auth = () => {
  const { token } = useAuth();
  const location = useLocation();

  return (
    <div className="w-full bg-gray-50 border-y-2 h-fit min-h-screen">
      <div className="flex mx-auto w-full items-center">{token?.length ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />}</div>
    </div>
  );
};

export default Auth;
