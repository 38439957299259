import { createContext, useState } from 'react';

const AddressContext = createContext({});

export const AddressProvider = ({ children }) => {
  const ADDRESS_LOCALSTORAGE_KEY = 'current_address';
  const localStorage = window.localStorage;

  const [currentAddress, setCurrentAddress] = useState(localStorage.getItem(ADDRESS_LOCALSTORAGE_KEY) || '');

  const setStoredAddress = (address) => {
    localStorage.setItem(ADDRESS_LOCALSTORAGE_KEY, JSON.stringify(address));
    setCurrentAddress(address);
  };

  const clearStoredAddress = () => {
    localStorage.removeItem(ADDRESS_LOCALSTORAGE_KEY);
    setCurrentAddress('');
  };

  const getStoredAddress = () => {
    return localStorage.getItem(ADDRESS_LOCALSTORAGE_KEY);
  };

  return (
    <AddressContext.Provider value={{ currentAddress, setCurrentAddress, setStoredAddress, clearStoredAddress, getStoredAddress }}>
      {children}
    </AddressContext.Provider>
  );
};

export default AddressContext;
