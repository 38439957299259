import { useNavigate } from 'react-router-dom';

import ReceiptCard from '../components/Receipt/ReceiptCard';
import DynamicIcon from '../components/shared/DynamicIcon';
import useCart from '../hooks/useCart';

const Receipt = () => {
  const navigate = useNavigate();
  const { clearStoredCart } = useCart();

  const handleClick = () => {
    clearStoredCart();
    navigate('/user/recent-orders');
  };

  return (
    <div className="w-full">
      <div className="h-full px-4">
        <div className="flex flex-col mx-auto max-w-7xl">
          <div className="flex items-center my-5 cursor-pointer place-self-end w-fit hover:opacity-90" onClick={handleClick}>
            <span className="w-full mr-2 font-medium text-dark text-md">See recent orders</span>
            <div className="flex items-center self-end justify-center w-10 h-10 p-1 transition-all duration-200 ease-in bg-gray-100 rounded-full cursor-pointer sm:mx-0 text-primary">
              <DynamicIcon icon="ChevronRightIcon" width="w-8" height="h-8" aria-hidden="true" />
            </div>
          </div>
          <ReceiptCard />
        </div>
      </div>
    </div>
  );
};

export default Receipt;
