import { useEffect, useState } from 'react';

import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import useAddress from '../../hooks/useAddress';
import useRestaurant from '../../hooks/useRestaurant';
import DynamicIcon from '../shared/DynamicIcon';

const RestaurantSearch = ({ search }) => {
  const [queryPlaces, setQueryPlaces] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);

  const { company } = useRestaurant();
  const { setStoredAddress } = useAddress();

  // useEffect(() => {
  //   if (currentAddress) {
  //     const adr = JSON.parse(currentAddress);
  //     setAddress(adr.address);
  //     // setSelectedAddress(adr);
  //   }
  // }, [currentAddress]);

  const searchAddress = _.debounce(async () => {
    if (address.split(' ').join('') === '') {
      setQueryPlaces([]);
      return;
    } else {
      try {
        const response = await axios.get(`https://api.dataforsyningen.dk/autocomplete?q=${address}&fuzzy=`);
        setQueryPlaces(response.data);
        setShowDropdown(true);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        toast.warn('Cannot get addresses at this time...');
      }
    }
  }, 1000);

  const selectAddress = (address) => {
    setAddress(address.tekst);
    setSelectedAddress(address);
    setShowDropdown(false);
  };

  const searchRestaurants = () => {
    if (!selectedAddress) {
      return;
    }

    if (selectedAddress && selectedAddress.data.y) {
      console.log(selectedAddress);

      const formattedAddress = {
        address: selectedAddress.tekst,
        street: selectedAddress.data.vejnavn,
        streetNo: selectedAddress.data.husnr,
        city: selectedAddress.data.postnrnavn,
        district: selectedAddress.data.kommunekode,
        postalCode: selectedAddress.data.postnr,
        latitude: selectedAddress.data.y,
        longitude: selectedAddress.data.x
      };

      setStoredAddress(formattedAddress);

      // setAddress(selectedAddress.tekst);
      setShowDropdown(false);
      search();
    } else {
      toast.warn('Full address is required!');
    }
  };

  return (
    <div className="flex flex-col text-white md:w-2/5">
      {/* <h1 className="text-4xl font-medium">{company.Name}</h1> */}
      <div className="mt-2">
        <p className="mb-2 text-xl">Add your delivery location to find the closest restaurant:</p>
        <div className="flex flex-row bg-white rounded-xl">
          <input
            // id="address"
            // name="address"
            type="text"
            // autoComplete="address"
            onChange={(e) => {
              searchAddress();
              setAddress(e.target.value);
            }}
            value={address}
            required
            className="w-4/5 px-3 py-3 text-left placeholder-gray-500 border-0 rounded-l-xl text-dark focus:outline-none focus:ring-0 focus:z-10 text-md"
            placeholder="Your location"
          />
          <div
            className={`${!selectedAddress ? 'bg-gray-400' : 'bg-primary cursor-pointer hover:opacity-90'} rounded-r-xl w-1/5 flex items-center justify-center`}
            onClick={searchRestaurants}>
            <DynamicIcon icon="MagnifyingGlassIcon" />
          </div>
        </div>
        {queryPlaces.length > 0 && showDropdown && address.length ? (
          <div className="w-4/5 h-40 px-3 py-2 mt-2 text-left bg-white border-0 rounded-xl min-h-fit text-dark text-md">
            {queryPlaces.slice(0, 3).map((place, index) => (
              <div
                key={index}
                onClick={() => {
                  selectAddress(place);
                }}
                className="flex flex-col justify-center w-full px-1 transition-all duration-300 ease-in-out border-b border-gray-300 h-1/3 hover:bg-gray-100 hover:opacity-90 hover:cursor-pointer">
                <div className="flex flex-row">
                  <p className="font-medium">{place.tekst}</p>
                  <p className="text-sm">{place.adressebetegnelse}</p>

                  <p className="text-sm">{place.postnrnavn}</p>

                  <p className="text-sm">{place.kommunenavn}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RestaurantSearch;
