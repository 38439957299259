const generateOrderNumber = () => {
  let prefix = '#';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let numbers = '0123456789';
  let randomCharacters = '';
  let randomNumbers = '';

  for (let i = 0; i < 3; i++) {
    randomCharacters += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  for (let i = 0; i < 3; i++) {
    randomNumbers += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  return prefix + randomCharacters + randomNumbers + characters.charAt(Math.floor(Math.random() * characters.length));
};

export default generateOrderNumber;
