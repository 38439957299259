import React, { Fragment, useContext, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import noImage from '../../assets/images/no_image.png';
import RestaurantContext from '../../contexts/RestaurantContext';
import useAddress from '../../hooks/useAddress';
import useAuth from '../../hooks/useAuth';
import useCart from '../../hooks/useCart';
import AuthModal from '../Auth/AuthModal';
import DynamicIcon from './DynamicIcon';

const Navbar = () => {
  const [authModal, setAuthModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const navigate = useNavigate();
  const { company } = useContext(RestaurantContext);
  const { token, clearStoredToken } = useAuth();
  const { clearStoredCart } = useCart();
  const { clearStoredAddress } = useAddress();

  const authLinks = [
    {
      name: 'Log in',
      onClick: () => {
        toggleAuthModal(true);
        setModalType('login');
      }
    },
    {
      name: 'Register',
      onClick: () => {
        toggleAuthModal(true);
        setModalType('register');
      }
    }
  ];

  const pages = [{ name: 'Profile', path: '/user', icon: 'UserIcon' }];

  const toggleAuthModal = () => {
    setAuthModal(!authModal);
  };

  const logout = () => {
    clearStoredToken();
    clearStoredCart();
    clearStoredAddress();
    navigate('/', { replace: true });
  };

  return (
    <>
      <nav className="flex justify-center py-3 bg-white ">
        <div className="flex items-center justify-between w-full px-4 max-w-7xl">
          <Link to="/">
            {company?.Image ? (
              <img
                src={`https://dk.takeoutresource.net/uploads/images/240x240/${company?.Image}`}
                alt={`${company?.Name} logo`}
                className="object-cover w-10 h-10"
              />
            ) : (
              <img src={noImage} alt="menu_item" className="object-contain w-full h-10" />
            )}
          </Link>
          {!token.length ? (
            <ul className="flex justify-end space-x-3">
              {authLinks.map((link) => (
                <li onClick={link.onClick} key={link.name} className="px-6 py-1 text-xl border-2 rounded-full cursor-pointer border-primary hover:opacity-90">
                  <div>{link.name}</div>
                </li>
              ))}
            </ul>
          ) : (
            <ProfileDropdown pages={pages} logout={logout} />
          )}
        </div>
      </nav>
      {authModal && modalType && <AuthModal open={authModal} close={toggleAuthModal} modalType={modalType} />}
    </>
  );
};

export default Navbar;

const ProfileDropdown = ({ logout, pages }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 space-x-2 font-medium transition-all duration-300 bg-white border-2 rounded-full shadow-sm outline-none cursor-pointer border-primary text-md text-dark hover:bg-primary hover:text-white ring-none">
          <div>Profile</div>
          <DynamicIcon icon="ChevronDownIcon" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-50 mt-2 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg w-44 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {pages.map((page) => (
              <Menu.Item key={page.path}>
                {({ active }) => (
                  <NavLink
                    to={page.path}
                    className={classNames(
                      active ? 'bg-primary text-white' : 'text-dark',
                      'flex space-x-2 items-center px-4 py-2 text-sm transition-all duration-100 cursor-pointer'
                    )}>
                    <DynamicIcon icon={page.icon} />
                    <div>{page.name}</div>
                  </NavLink>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={logout}
                  className={classNames(
                    active ? 'bg-primary text-white' : 'text-dark',
                    'flex space-x-2 items-center px-4 py-2 text-sm transition-all duration-300 cursor-pointer'
                  )}>
                  <DynamicIcon icon="ArrowLeftOnRectangleIcon" />
                  <div>Logout</div>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
