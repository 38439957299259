import React, { useEffect, useState } from 'react';

import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import useAddress from '../../../hooks/useAddress';
import useOrder from '../../../hooks/useOrder';

const SearchAddressElement = ({ value, onChange, address, setAddress, labelText, placeholder }) => {
  const [queryPlaces, setQueryPlaces] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const { selectedAddress, setSelectedAddress } = useOrder();
  const { getStoredAddress, currentAddress, setStoredAddress, clearStoredAddress } = useAddress();

  useEffect(() => {
    const current_address = getStoredAddress();
    if (current_address !== null) {
      const _address = JSON.parse(current_address);
      setAddress(_address.address);
      setSelectedAddress(_address.address);
      // console.log(currentAddress);
    }
  }, [currentAddress]);

  const searchAddress = _.debounce(async () => {
    if (address.split(' ').join('') === '') {
      setQueryPlaces([]);
      return;
    } else {
      try {
        const response = await axios.get(`https://api.dataforsyningen.dk/autocomplete?q=${address}&fuzzy=`);
        setQueryPlaces(response.data);
        setShowDropdown(true);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        toast.warn('Cannot get addresses at this time...');
      }
    }
  }, 1000);

  const selectAddress = (address) => {
    // setAddress(address.tekst);
    //TODO: setStoredAddress ??

    if (address && address.data.y) {
      const formattedAddress = {
        address: address.tekst,
        street: address.data.vejnavn,
        streetNo: address.data.husnr,
        city: address.data.postnrnavn,
        district: address.data.kommunekode,
        postalCode: address.data.postnr,
        latitude: address.data.y,
        longitude: address.data.x
      };

      setStoredAddress(formattedAddress);

      setSelectedAddress(address.tekst);
      setShowDropdown(false);
      setAddress(address.tekst);
      setShowDropdown(false);
    } else {
      toast.warn('Full address is required!');
    }
  };

  return (
    <>
      <div className="text-left">
        {labelText && <label>{labelText}</label>}
        <input
          type="text"
          onChange={(e) => {
            searchAddress();
            setAddress(e.target.value);
            clearStoredAddress();
            setSelectedAddress(null);
          }}
          value={address}
          required
          placeholder={placeholder && placeholder}
          className="mt-0.5 rounded-xl bg-transparent w-full px-3 py-3 border-2 border-dark placeholder-gray-300 text-dark text-left focus:outline-none focus:ring-primary focus:border-primary focus:z-10 text-md"
        />
      </div>
      {queryPlaces.length > 0 && showDropdown && address.length ? (
        <div className="w-full h-40 px-3 py-2 mt-2 text-left bg-white border shadow-md border-dark rounded-xl min-h-fit text-dark text-md">
          {queryPlaces.slice(0, 3).map((place, index) => (
            <div
              key={index}
              onClick={() => {
                selectAddress(place);
              }}
              className="flex flex-col justify-center w-full px-1 transition-all duration-300 ease-in-out border-b border-gray-300 h-1/3 hover:bg-gray-100 hover:opacity-90 hover:cursor-pointer">
              <div className="flex flex-row">
                <p className="font-medium">{place.tekst}</p>
                <p className="text-sm">{place.adressebetegnelse}</p>

                <p className="text-sm">{place.postnrnavn}</p>

                <p className="text-sm">{place.kommunenavn}</p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
export default SearchAddressElement;
