const RadioElement = ({ name, value, checked, onChange, labelText, big }) => {
  return (
    <div className="flex items-center">
      <input
        id={name}
        name={name}
        type="radio"
        className="w-5 h-5 border-2 border-gray-400 text-primary focus:ring-primary hover:cursor-pointer"
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <label htmlFor={name} className={`${big ? 'text-lg' : 'text-md'} ml-3 text-left block text-dark`} onClick={onChange}>
        {labelText}
      </label>
    </div>
  );
};

export default RadioElement;
