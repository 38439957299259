import Heading from '../../components/shared/Heading';
import RecentOrderList from '../../components/User/RecentOrders/RecentOrderList';

const UserRecentOrders = () => {
  return (
    <div className="max-w-7xl w-full space-y-5">
      <Heading text="Recent orders" big />
      <RecentOrderList />
    </div>
  );
};

export default UserRecentOrders;
