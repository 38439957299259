import { useEffect, useState } from 'react';

import { Tab } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useCart from '../../hooks/useCart';
import useOrder from '../../hooks/useOrder';
import useRestaurant from '../../hooks/useRestaurant';
import { useGetRestaurant } from '../../hooks/useRestaurantQuery';
import DeliveryDetails from './CheckoutDetails/DeliveryDetails';
import PickupDetails from './CheckoutDetails/PickupDetails';

const CheckoutDetails = () => {
  // const [mode, setMode] = useState('Delivery');

  const navigate = useNavigate();
  const { cart } = useCart();
  const { mode, setMode } = useOrder();
  const { restaurant, setRestaurant, restaurantId } = useRestaurant();

  const onSuccess = (data) => {
    console.log(data);
    setRestaurant(data.data?.Data);
  };

  const onError = (error) => {
    console.log(error);
    toast.error('Something went wrong, please try again later!');
    navigate('/order');
  };

  const { data } = useGetRestaurant(onSuccess, onError, restaurantId);

  useEffect(() => {
    if (mode.length) {
      setMode(mode);
    } else {
      setMode('Delivery');
    }
  }, [mode]);

  return (
    <>
      {restaurant && (
        <div className="w-full">
          <h1 className="text-2xl font-medium text-white">Your order from {restaurant?.Name}</h1>
          {restaurant && restaurant?.DeliveryRanges?.length > 0 && (
            <h2 className="text-xl text-white">
              Delivery from {restaurant?.DeliveryRanges[0]?.Time} - {restaurant?.DeliveryRanges[restaurant?.DeliveryRanges?.length - 1]?.Time} minutes
            </h2>
          )}
          <div className="flex-1 w-full h-full p-5 mt-2 mb-4 bg-white shadow-md rounded-3xl">
            <Tab.Group>
              <Tab.List className="flex p-0 border-2 rounded-2xl border-secondary">
                <Tab
                  onClick={() => setMode('Delivery')}
                  className={`w-full ring-0 ring-offset-0 outline-none leading-5 rounded-2xl py-2.5 text-md font-medium text-white ${
                    mode === 'Delivery' ? 'bg-secondary ring-offset-2 ring-2 rounded-2xl ring-secondary' : 'text-dark hover:bg-secondary/[0.02]'
                  }`}>
                  Delivery
                </Tab>
                <Tab
                  onClick={() => setMode('Pickup')}
                  className={`w-full ring-0 ring-offset-0 outline-none leading-5 rounded-2xl py-2.5 text-md font-medium text-white ${
                    mode === 'Pickup' ? 'bg-secondary ring-offset-2 ring-2 rounded-2xl ring-secondary' : 'text-dark hover:bg-secondary/[0.02]'
                  }`}>
                  Pickup
                </Tab>
              </Tab.List>

              {mode === 'Delivery' && <DeliveryDetails mode={mode} />}
              {mode === 'Pickup' && <PickupDetails mode={mode} />}
            </Tab.Group>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutDetails;
