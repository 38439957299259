import React from 'react';

const RestaurantCategorySkeleton = () => {
  return (
    <div className="rounded-3xl px-6 py-3 w-full mx-auto bg-white h-fit mb-4">
      <div className="animate-pulse flex space-x-10 w-full items-center">
        <div className="flex-1 space-y-5 py-1">
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="h-5 bg-gray-300 rounded"></div>
          <div className="h-5 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCategorySkeleton;
